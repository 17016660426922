import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Dropdown } from 'react-bootstrap';
import team3 from 'assets/img/team/3.jpg';
import Avatar from 'components/common/Avatar';
import avatar from 'assets/img/team/avatar.png';
import { useTranslation } from 'react-i18next';
import { logout } from 'services/authService';
import { toast } from 'react-toastify';
import toastConfig from 'components/configuration/toast-config';
import { loaderContext } from 'context/Context';

const ProfileDropdown = () => {
  const { t } = useTranslation();
  const { isLoading, setIsLoading } = useContext(loaderContext)
  const navigation = useNavigate();
  const userName = localStorage.getItem("userName");
  const logoutUser = async () => {
    setIsLoading(true)
    try {
      const { data } = await logout();
      toast.success(data?.message, toastConfig)
      localStorage.removeItem("authToken")
      localStorage.removeItem('userName')
      navigation('/login', { replace: true });
    } catch (error) {
      console.log(error)
    }
    setIsLoading(false)
  }
  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        <div className="d-flex align-items-center profile-pic">
          <Avatar src={avatar} />
          <span className="ms-2 d-none d-sm-block profile-text fw-600">
            {userName}
          </span>
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item onClick={() => logoutUser()}>
            {t('navbar.profile.logout')}
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
