import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { Modal } from 'react-bootstrap'
import Webcam from 'react-webcam';

export default function CapturePhotoModal({ isModalOpen, setIsModalOpen, setUserPhoto }) {
    const videoConstraints = {
        width: 720,
        height: 450,
        facingMode: "user"
    };
    const webcamRef = React.useRef(null);
    const capture = React.useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            setUserPhoto(imageSrc)
            setIsModalOpen(false)
        },
        [webcamRef]
    );
    return (
        <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)} >
            <Modal.Header className="heading-color fw-600 guestregistermodalheader" >Capture Photo
                <i closeButton className="fa  fa-times-circle modal-close-btn" aria-hidden="true" onClick={() => setIsModalOpen(false)}></i>
            </Modal.Header>
            <Modal.Body className='modalbody'>
                <div className='d-flex' style={{ flexDirection: "column" }}>
                    <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        videoConstraints={videoConstraints}
                        mirrored={true}
                    />
                    <button onClick={capture} style={{ width: "fit-content", borderRadius: "100%", padding: "10px 15px", position: "absolute", left: "47%", bottom: "5%", cursor: "pointer" }}>
                        <FontAwesomeIcon icon="camera" height="40px" />
                    </button>
                </div>
            </Modal.Body>
            {/* </div> */}
        </Modal>
    )
}
