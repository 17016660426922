import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OutsideClickHandler from 'react-outside-click-handler';
import allCountryCodes from 'components/configuration/country-code';
import { defaultCountryCode } from 'components/configuration/country-code';
import '../../assets/css/custom-dropdown.css';
import _ from 'lodash';

export default function PhoneNumber({
  value,
  onChange,
  countryCode,
  setCountryCode,
  placeholder,
  error,
  firstError,
  secondError,
  dropdownDirection,
  saperate,
  firstPlaceholder,
  secondPlaceholder,
  flagvisible,
  email,
  formFieldsDisabled,
  wantemail,
  phoneNumber,
  setEmail,
  setPhoneNumber,
  disableboth,
}) {
  const [dropdownStatus, setDropdownStatus] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [focusIndex, setFocusIndex] = useState();
  const [countryCodes, setCountryCodes] = useState();
  useEffect(() => {
    setCountryCodes(allCountryCodes);
  }, []);
  useEffect(() => {
    setCountryCodes(
      _.filter(allCountryCodes, country => {
        if (
          country.dial_code.toString().includes(searchQuery.toLowerCase()) ||
          country.name.toLowerCase().includes(searchQuery.toLowerCase())
        ) {
          return true;
        }
      })
    );
  }, [searchQuery]);
  const changeCountry = data => {
    setCountryCode(data);
    setSearchQuery('');
    setDropdownStatus(false);
  };
  const handleOutsideClick = () => {
    setSearchQuery('');
    setDropdownStatus(false);
  };
  const isDisabled = formFieldsDisabled;
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        handleOutsideClick();
      }}
    >

      {saperate ?
        <div className="d-flex align-items-center flex-wrap d-grid gap-3">
          <div role="button">
            <div
              className="d-flex align-items-center form-control py-0 px-2 country-dropdown"
              onClick={disableboth ? null : () => setDropdownStatus(!dropdownStatus)}
            >
              <div
                className="d-flex align-items-center"
                title={`${countryCode.name} : ${countryCode.dial_code}`}
              >
                {
                  flagvisible && (
                    <img className="me-1 flag-image" src={`/admin-images/country-flag/4x3/${countryCode.code.toLowerCase()}.svg`} />
                  )
                }

                <span className="me-1">{countryCode.dial_code}</span>
                <FontAwesomeIcon
                  icon={`${dropdownStatus ? 'caret-up' : 'caret-down'}`}
                  className="ms-1"
                />
              </div>
            </div>

            {dropdownStatus && (
              <div
                className={`dropdown-options position-absolute p-2 ${dropdownDirection === 'top'
                  ? 'bottom-100'
                  : dropdownDirection === 'bottom' && ''
                  }`}
              >
                <input
                  className="form-control search-bar"
                  type="text"
                  placeholder="Search country"
                  value={searchQuery}
                  onChange={e => setSearchQuery(e.target.value)}
                />
                {countryCodes.length > 0 ? (
                  countryCodes.map((country, index) => (
                    <div
                      key={index}
                      onClick={() => changeCountry(country)}
                      className="country-name"
                      role="button"
                    >
                      <img className="me-1 flag-image" src={`/admin-images/country-flag/4x3/${country.code.toLowerCase()}.svg`} />
                      <span className="me-1">{country.name}</span>
                      <span className="me-4">{country.dial_code}</span>
                    </div>
                  ))
                ) : (
                  <div className="text-center">
                    <h5 className="text-danger">Data not found.</h5>
                  </div>
                )}
              </div>
            )}
          </div>
          <input
            style={{ maxHeight: "2.5rem", width: "12rem" }}
            className={`form-control zindex-0 ${firstError ? 'is-invalid' : ''}`}
            type="text"
            onFocus={() => setFocusIndex(1)}
            disabled={isDisabled || focusIndex == 2 || email != "" || (disableboth && phoneNumber != "")}
            onBlur={() => setFocusIndex("")}
            value={phoneNumber}
            onChange={(e) => { setPhoneNumber(e.target.value) }}
            placeholder={firstPlaceholder}
          />
          {
            wantemail && (
              <>
                <div class="d-flex">
                  <span class="or text-dark">OR</span>
                </div>
                <input
                  style={{ maxHeight: "2.5rem", width: "14rem" }}
                  className={`form-control zindex-0 ${secondError ? 'is-invalid' : ''}`}
                  type="text"
                  onFocus={() => setFocusIndex(2)}
                  disabled={isDisabled || focusIndex == 1 || phoneNumber != "" || (disableboth && email != "")}
                  value={email}
                  onBlur={() => setFocusIndex("")}
                  onAbort={() => setFocusIndex()}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={secondPlaceholder}
                />
              </>
            )
          }
          {/* <div class="d-flex">
            <span class="or text-dark">OR</span>
          </div>
          <input
            style={{ maxHeight: "2.5rem", width: "14rem" }}
            className={`form-control zindex-0 ${secondError ? 'is-invalid' : ''}`}
            type="text"
            onFocus={() => setFocusIndex(2)}
            disabled={focusIndex == 1 || phoneNumber != "" || (disableboth && email != "")}
            value={email}
            onBlur={() => setFocusIndex("")}
            onAbort={() => setFocusIndex()}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={secondPlaceholder}
          /> */}
        </div>
        :
        <div className="input-group">
          <div className="input-group-prepend " role="button">
            <div
              className="d-flex align-items-center form-control py-0 px-2 country-dropdown"
              onClick={() => setDropdownStatus(!dropdownStatus)}
            >
              <div
                className="d-flex align-items-center"
                title={`${countryCode.name} : ${countryCode.dial_code}`}
              >
                {/* <img className="me-1 flag-image" src={countryCode.code} /> */}
                <span className="me-1">{countryCode.dial_code}</span>
                <FontAwesomeIcon
                  icon={`${dropdownStatus ? 'caret-up' : 'caret-down'}`}
                  className="ms-1"
                />
              </div>
            </div>

            {dropdownStatus && (
              <div
                className={`dropdown-options position-absolute p-2 ${dropdownDirection === 'top'
                  ? 'bottom-100'
                  : dropdownDirection === 'bottom' && ''
                  }`}
              >
                <input
                  className="form-control search-bar"
                  type="text"
                  placeholder="Search country"
                  value={searchQuery}
                  onChange={e => setSearchQuery(e.target.value)}
                />
                {countryCodes.length > 0 ? (
                  countryCodes.map((country, index) => (
                    <div
                      key={index}
                      onClick={() => changeCountry(country)}
                      className="country-name"
                      role="button"
                    >
                      <img className="me-1 flag-image" src={`/admin-images/country-flag/4x3/${country.code.toLowerCase()}.svg`} />
                      <span className="me-1">{country.name}</span>
                      <span className="me-4">{country.dial_code}</span>
                    </div>
                  ))
                ) : (
                  <div className="text-center">
                    <h5 className="text-danger">Data not found.</h5>
                  </div>
                )}
              </div>
            )}
          </div>
          <input
            className={`form-control zindex-0 ${error ? 'is-invalid' : ''}`}
            type="text"
            value={!value ? '' : value}
            onChange={onChange}
            placeholder={placeholder}
          />
        </div>
      }

    </OutsideClickHandler>
  );
}
PhoneNumber.defaultProps = {
  countryCode: defaultCountryCode,
  placehoder: '',
  dropdownDirection: 'top'
};
