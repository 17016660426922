import httpServices from './httpServices';
import { common } from './config';

export const getDocType = async () => {
  return await httpServices.get(common.getDocType,
    {
      headers: {
        authorization: localStorage.getItem('authToken')
      }
    }
  );
};

export const verifyAccessCard = async (data) => {
  return await httpServices.post(common.verifyAccessCard, data)
}

export const listsupplier = async () => {
  return await httpServices.get(common.listsupplier)
}