import React from 'react';
import Select from 'react-select';
import '../../assets/css/searchable-dropdown.css';
import { createFilter } from 'react-select';

export default function SearchableDropdown({
  isClearable,
  isRtl,
  isSearchable,
  options,
  value,
  onChange,
  placeholder,
  hasError,
  defaultValue,
  noOptionsMessage,
  formFieldsDisabled,
  isMulti,
  searchLabel,
  searchValue,
}) {
  const isDisabled = formFieldsDisabled;
  return (
    <>

      <div
        className={`d-felx flex-row form-control p-0 ${hasError && 'is-invalid'
          } dropdown-wrapper`}
      >
        <Select
          className={`dropdown ${hasError && 'pe-4'}`}
          classNamePrefix="select"
          isClearable={isClearable}
          isRtl={isRtl}
          isSearchable={isSearchable}
          placeholder={placeholder}
          value={value}
          isDisabled={isDisabled}
          onChange={onChange}
          defaultValue={defaultValue}
          name="color"
          menuPortalTarget={document.body}
          options={options}
          noOptionsMessage={noOptionsMessage}
          isMulti={isMulti}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          menuPlacement="auto"
          filterOption={createFilter({
            ignoreCase: true,
            ignoreAccents: true,
            matchFrom: 'any',
            stringify: option =>
              `${searchLabel && option.label} ${searchValue && option.value}`,
            trim: true
          })}
        />
      </div>
    </>
  );
}

SearchableDropdown.defaultProps = {
  isClearable: false,
  isSearchable: true,
  isRtl: false,
  isMulti: false,
  searchLabel: true,
  searchValue: false,
  noOptionsMessage: () => 'No options'
};
