import React from 'react';
import { useTranslation } from 'react-i18next';
import ForgetPasswordForm from 'components/authentication/ForgetPasswordForm';
import Logo from "../../../assets/logo/Centenary_Logo.png"
import "../css/forgot-password.css"

const ForgetPassword = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="d-flex flex-column align-items-center mb-2">
        <img src={Logo} className='company-logo' />
        <h2 className="mt-4 text-center">{t("forgotPasswordPage.heading")}</h2>
        <p className='text-center text-black'>{t("forgotPasswordPage.description")}</p>
      </div>
      <ForgetPasswordForm />
    </div>
  );
};

export default ForgetPassword;
