import { authEndpoints } from "./config";
import httpServices from "./httpServices";

export const authenticateUser = async userCredentials => {
    return await httpServices.post(authEndpoints.login, userCredentials);
};

export const forgotPassword = async userEmail => {
    return await httpServices.post(authEndpoints.forgotPassword, userEmail);
}
export const resetPassword = async userPassword => {
    return await httpServices.post(authEndpoints.resetPassword, userPassword)
}
export const verifyOTP = async otpData => {
    return await httpServices.post(authEndpoints.verifyOTP, otpData)
}

export const logout = async () => {
    return await httpServices.post(authEndpoints.logout)
}