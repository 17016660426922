const baseUrl = process.env.REACT_APP_BASE_URL;
// const baseUrl = 'http://192.168.0.152/php/secuviz/public/api/'
const memberUrl = process.env.REACT_APP_MEMBER_URL;
const mockUrl = "http://192.168.1.8:1080/"

export const masterEndpoints = {
  // company master endpoints
  getCompanies: `${baseUrl}/master/company-master/get-companies`,

  // department master endpoints
  getDepartments: `${baseUrl}/master/department-master/get-departments`,

  // employee master endpoints
  getEmployees: `${baseUrl}/master/employee-master/get-employees`,

  // asset master endpoints
  getAssets: `${baseUrl}/master/asset-master/get-assets`
};

export const frontDeskEndpoints = {
  // visitor registrations endpoints
  addVisitorRegistration: `${baseUrl}/secureViz/visitor-registration`,
  sendOTP: `${baseUrl}/secureViz/visitor-registration/send-otp`,
  getVisitors: `${baseUrl}/secureViz/visitor-registration/get-visitors`,
  logOutVisitor: `${baseUrl}/secureViz/logout-visitor`,
  groupLeaderData: `${baseUrl}/secureViz/group-visit/group-leader-data`,
  memberLogout: `${baseUrl}/secureViz/group-visit/logout-member`
};

// authEndpoints
export const authEndpoints = {
  login: `${baseUrl}signin`,
  forgotPassword: `${baseUrl}forgot-password`,
  resetPassword: `${baseUrl}reset-password`,
  verifyOTP: `${baseUrl}verify-otp`,
  logout: `${baseUrl}logout`
};
export const guestRegistrationEndPoints = {
  getDestinations: `${baseUrl}list-destinations`,
  registerGuest: `${baseUrl}register-guest`,
  guestRegistrationOtp: `${baseUrl}guest-registration-otp`,
  identificationTypeList: `${baseUrl}list-identification-types`,
  getMemberDetails: `${memberUrl}apisrv/index`
}
export const guestBookingEndPoints = {
  listGuest: `${baseUrl}list-guests`,
  registerGuest: `${baseUrl}guest/1`,
}
export const visitorRegistrationEndPoints = {
  listVisitorType: `${baseUrl}list-visitor-types`,
  listDepartment: `${baseUrl}list-department-types`,
  listEmployee: `${baseUrl}list-employees`,
  visitorRegister: `${baseUrl}visitor`,
  verifyOtp: `${baseUrl}send-visitor-otp`,
  bypass: `${baseUrl}verify-admin-details`,
  getmanagefield: `${baseUrl}manage-frontdesk-list`
}
export const visitorAppointmentEndPoints = {
  listVisitorAppointment: `${baseUrl}visitor_appoitment`,
  visitorAppointmentRegisterData: `${baseUrl}update-visitor-appoitment`,
  visitorBypass: `${baseUrl}visitor-appoitment-bypass`
}
export const eventEndPoints = {
  listevent: `${baseUrl}event-list`,
  status: `${baseUrl}guest-arrive-status`
}
export const commonScanDocEndPoints = {
  imageIdentity: `${baseUrl}verify-image-identity`
}
export const logoutEndPoints = {
  logout: `${baseUrl}logout-visitor`,
  listLogout: `${baseUrl}logout-list`
}

export const adminEndPoints = {
  // group visit endpoints
  getGroupVisits: `${baseUrl}/admin/manage-group-visit/get-group-visits`,
  getAppointments: `${baseUrl}/appointment/get-appointments`,
  getAppointment: `${baseUrl}/appointment/get-appointment`,
  getGroupData: `${baseUrl}/admin/manage-group-visit/get-group-visits`
};

export const parameterSetup = {
  // add visitor type
  getVisitorType: `${baseUrl}/parameter-setup/visitor-type/get-visitor-type`
}

// common
export const common = {
  getDocType: `${baseUrl}/get-document-types`,
  listsupplier: `${baseUrl}supplier-contractor-list`,
  verifyAccessCard: `${baseUrl}scan-card`
};