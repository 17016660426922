import React from 'react';
import { Navbar } from 'react-bootstrap';
import CentenaryLogo from 'assets/logo/Centenary_Logo2.png';
// import NameLogo from 'assets/logo/name-logo.png';
import TopNavRightSideNavItem from './TopNavRightSideNavItem';
import { useTranslation } from 'react-i18next';
import './NavbarTop.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { navbar } from 'routes/routes';
import { NavLink } from 'react-router-dom';
import 'assets/css/navbar.css';
import { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';


export default function () {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false)

  return (
    <>
      {/* <div
        className="offcanvas offcanvas-start"
        id="demo"
        style={{ backgroundColor: '#edf2f9' }}
      >
        <div className="offcanvas-header">
          <h1></h1>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
          ></button>
        </div>
        <div className="offcanvas-body">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            {navbar.children.map((route, index) => (
              <NavLink key={index} className="nav-items mx-3" to={route.to}>
                <li className="nav-item" data-bs-dismiss="offcanvas">
                  <div className="px-2 py-3" >
                    <FontAwesomeIcon icon={route.icon} />
                    <span className="ms-1">{t(route.name)}</span>
                  </div>
                </li>
              </NavLink>
            ))}
          </ul>
        </div>
      </div>
      <div className="container-fluid">
        <button
          className="btn btn-primary rounded-pill py-3 px-2  position-absolute showtogglebutton"
          style={{ margin: '1rem 0rem 0rem -1.6rem' }}
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#demo"
        >
          <span className="navbar-toggle-icon">
            <span className="toggle-line" />
          </span>
        </button>
      </div> */}



      {/* <Navbar className="py-0  nav-header">
        <img className="me-2" src={CentenaryLogo} width={100} />
        <h5 className="fw-bold logotext">{t('navbar.logo.logoName')}</h5> */}
      {/* <img src={NameLogo} width={150} />
      {/* <TopNavRightSideNavItem /> */}
      {/* </Navbar> */}
      {/* <div className='hamburger' data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation" >
        <MenuIcon />
      </div> */}

      {/* <nav
        className="navbar navbar-expand-lg navbar-light justify-content-lg-center nav-footer px-0 "
      >
        <div className="">
          <div className='navbar-collapse collapse' id="navbarToggleExternalContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              {navbar.children.map((route, index) => (
                <NavLink key={index} className="nav-items mx-3" to={route.to}>
                  <li className="nav-item">
                    <div className="navbar-items">
                      <FontAwesomeIcon icon={route.icon} />
                      <span className="ms-1">{t(route.name)}</span>
                    </div>
                  </li>
                </NavLink>
              ))}
            </ul>
          </div>
        </div>
      </nav> */}








      <Navbar className="py-0 nav-header">
        <img className="me-2" src={CentenaryLogo} width={100} />
        <h5 className="fw-bold logotext">{t('navbar.logo.logoName')}</h5>
        <TopNavRightSideNavItem />
      </Navbar>
      <div className='hamburger' aria-expanded={open.toString()} aria-label="Toggle navigation" onClick={() => setOpen(!open)}>
        <MenuIcon />
      </div>
      <nav className="navbar navbar-expand-xl navbar-light justify-content-lg-center nav-footer navbar-footer px-0">

        <div className={`collapse navbar-collapse navigation  ${open ? "show" : ""} `} id="navbarToggleExternalContent" >
          <ul className="navbar-nav  mb-2 mb-lg-0" onClick={() => setOpen(false)}>
            {navbar.children.map((route, index) => (
              <NavLink key={index} className="nav-items mx-3" to={route.to}>
                <li className="nav-item">
                  <div className='nav-parent'>
                    <div className='nav-icons '>
                      <FontAwesomeIcon icon={route.icon} />
                    </div>
                    <div className="navbar-items">
                      <span className="ms-1">{t(route.name)}</span>
                    </div>
                  </div>
                </li>
              </NavLink>
            ))}
          </ul>
        </div>
      </nav>
    </>
  );
};
