import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchBox from 'components/common/SearchBox';
import '../../assets/css/pagination.css';
import {
  useTable,
  useExpanded,
  useFilters,
  useSortBy,
  usePagination,
  useGlobalFilter,
  useGroupBy
} from 'react-table';

import { t } from 'i18next';
import {
  getDateFormate,
  getTimeFormate,
  getDateAndTimeFormate
} from './helper-function/reactTableHelper';
import '../../assets/css/ReactTable.css';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import EventNestedTable from 'components/pages/events/EventNestedTable';
import { useLocation, useNavigate, useNavigation, useParams } from 'react-router-dom';





export default function ReactTable({
  columns,
  data,
  pageSizeOption,
  isPageNumberIndecatorRequired,
  isPageSizeDropdownRequired,
  isPaginationRequired,
  headerClass,
  headerNestedClass,
  innerTableSearch,
  isSearchBoxVisible,
  ispaginationvisible,
  component,
  widthClass,
  bodyClass,
  hedingName,
  RenderRowSubComponent,
  showHeader,
  pageNo = 0,
  setPageNO
}) {
  const [filterInput, setFilterInput] = useState('');
  const { t } = useTranslation();
  const [entry, setEntry] = useState('4');
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize },
    toggleRowExpanded,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: pageNo, pageSize: pageSizeOption[0] }
      // initialState: { pageIndex: 0, pageSize: 4 }
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  );

  const handleFilterChange = e => {
    const value = e.target.value || undefined;
    setGlobalFilter(value || undefined);
    setFilterInput(value === undefined ? '' : value);
  };

  useEffect(() => {
    setTimeout(() => {
      setPageSize(entry && entry != '' ? parseInt(entry) : 1)
    }, 1000);
  }, [entry])

  console.log("subrow")
  const PAGE_RANGE_DISPLAYED = 3;
  return (
    <>
      {
        isSearchBoxVisible && (
          <>
            <div className=" mb-3 mt-1 row ">
              <div className='col-lg-6 col-md-12'>
                <h3 className="heading-nestedtable-color fw-600">{t(hedingName)}</h3>
              </div>

              <div className="col-lg-6 col-md-12  searchablebox">
                <SearchBox onChange={handleFilterChange} value={filterInput} />
              </div>
            </div>
            <div className="col-md-12 mb-2" style={{ marginTop: "-1rem" }}>
              <hr />
            </div>

          </>
        )
      }
      {showHeader && (
        <>
          <div className="mb-3 mt-1 row ">
            {hedingName && <div className='col-lg-6 col-md-6'>
              <h3 className="heading-color fw-600">{t(hedingName)}</h3>
            </div>}
            {component && <div className="col-lg-6 col-md-6 d-flex justify-content-space-around">
              {component && component}
            </div>}
          </div>

          {hedingName && <div className="col-md-12" style={{ marginTop: "-1rem" }}>
            <hr />
          </div>}

          <div className={`d-flex flex-wrap gap-2 justify-content-between tablesearch  mb-3 ${innerTableSearch}`}>
            <div className="d-flex d-grid gap-2 align-items-center">
              <span className="showentris">{t('visitorLogout.search.show')}</span>
              <input type="text" name value={entry} className="form-control sortlist" onChange={(e) => setEntry((e.target.value))}></input>
              <span className="showentris">{t('visitorLogout.search.entries')}</span>
            </div>
            <div className="d-flex d-grid gap-1 align-items-center searchbox">
              <SearchBox onChange={handleFilterChange} value={filterInput} />
            </div>
          </div>
          {/* <div className="col-md-6">
           
          </div> */}
        </>
      )}
      <div className="table-responsive scrollbar mb-4">
        {/* style={{ minWidth: '50rem' }} */}
        <table
          {...getTableProps()}
          className={`table table-hover overflow-hidden mb-0 ${widthClass}`}
        >
          <thead className={headerClass}>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={`${column.className && column.className} ${column.headerClass} `}
                  >
                    {column.render('Header')}
                    {/* Add a sort direction indicator */}
                    {!column.disableSortBy && (
                      <span className="ms-2">
                        <FontAwesomeIcon
                          icon={
                            column.isSorted
                              ? column.isSortedDesc
                                ? 'arrow-down'
                                : 'arrow-up'
                              : 'sort'
                          }
                        />
                      </span>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className={bodyClass}>
            {page.length > 0 ? (
              page.map((row, i) => {
                prepareRow(row);
                console.log(row)
                return (
                  <React.Fragment key={row.id}>
                    <tr {...row.getRowProps()} >
                      {row.cells.map((cell, index) => {
                        return cell.column.content ? (
                          <td
                            {...cell.getCellProps()}
                            key={index}
                            className={`${cell.column.className && cell.column.className
                              }`}
                          >
                            {cell.column.content(cell.row.original.id)}
                          </td>
                        ) : cell.column.isImage ? (
                          <td
                            {...cell.getCellProps()}
                            key={index}
                            className={`${cell.column.className && cell.column.className
                              }`}
                          >
                            <img src={cell.value} height="32px" width="32px" />
                            <span>{cell.render('Cell')}</span>
                          </td>
                        ) : cell.column.valueType === 'date' ||
                          cell.column.valueType === 'dateAndTime' ||
                          cell.column.valueType === 'time' ? (
                          <td
                            key={index}
                            className={`${cell.column.className && cell.column.className
                              }`}
                          >
                            {cell.column.valueType === 'date'
                              ? moment(
                                cell.render('Cell').props.cell.value
                              ).format(
                                process.env.REACT_APP_DISPLAY_DATE_FORMAT
                              )
                              : cell.column.valueType === 'dateAndTime'
                                ? moment(
                                  cell.render('Cell').props.cell.value
                                ).format(
                                  process.env.REACT_APP_DISPLAY_DATE_TIME_FORMAT
                                )
                                : moment(
                                  cell.render('Cell').props.cell.value
                                ).format(
                                  process.env.REACT_APP_DISPLAY_TIME_FORMAT
                                )}
                          </td>

                        ) : (
                          <td
                            {...cell.getCellProps()}
                            key={index}
                            className={`${cell.column.className && cell.column.className
                              }`}
                            // onClick={() => toggleRowExpanded(row)}
                            onClick={() => {
                              page.map((currentRow) => {
                                if (currentRow.id === row.id) {
                                  if (row?.isExpanded) {
                                    toggleRowExpanded(row.id, false)
                                  } else {
                                    toggleRowExpanded(row.id, true)
                                  }
                                } else {
                                  toggleRowExpanded(currentRow.id, false)
                                }
                              })
                            }}
                          >
                            {cell.render('Cell')}

                          </td>
                        );
                      })}
                    </tr>
                    {(row?.isExpanded && RenderRowSubComponent) ? (
                      <>
                        <tr className={row?.isExpanded ? 'expanded' : 'collapsed'}>
                          <td colSpan={columns.length} className='event-inner-table'>
                            {/* {renderRowSubComponent({ row })} */}
                            <RenderRowSubComponent row={row} />
                          </td>
                        </tr>
                      </>
                    ) : null}
                    {/* {
                      row.isExpanded && (
                        <tr className={row.isExpanded ? 'expanded' : 'collapsed'}>
                          <td colSpan={columns.length} className='event-inner-table'>
                            <EventNestedTable data={row.original.eventnestedtabledata} />
                          </td>
                        </tr>
                      )
                    } */}
                  </React.Fragment>
                );

              })
            ) : (
              <tr>
                <td colSpan={`${columns.length + 1}`}>
                  <h5 className="text-center text-danger">
                    {t('table.dataNotFound')}
                  </h5>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>






      {/* {
        ispaginationvisible && (
          <>
            <div className={`paginationinfo ${innerTableSearch}`}>
              <p style={{ color: "#939aac" }}>
                {t('visitorLogout.search.showing')} {pageIndex * pageSize + 1} {t('visitorLogout.search.to')} {' '}
                {Math.min((pageIndex + 1) * pageSize, data.length)} {t('visitorLogout.search.of')} {data.length} {t('visitorLogout.search.entries')}
              </p>
            </div>

            <div className={`main-pagination ${innerTableSearch}`}>
              <div className="d-flex flex-wrap align-items-center">


              </div>
              {!canPreviousPage && !canNextPage && isPaginationRequired ? null : (
                <div className="d-flex flex-wrap">


                  <div className="d-flex pagination">
                    <button
                      onClick={() => previousPage()}
                      className={`btn-previous btn-sm btn-falcon-default  ${!canPreviousPage && 'disabled'
                        }`}

                    >
                      {t('visitorLogout.pagination.paginationBtn')}

                    </button>
                

                    {Array.from({ length: pageCount }, (_, i) => {
                      const isActive = i === pageIndex;

                      if (pageCount <= 3) {
                        return (
                          <span
                            key={i}
                            className={`pagination-btn ${isActive ? 'active' : ''}`}
                            onClick={() => { gotoPage(i); setPageNO(i); }}
                          >
                            {i + 1}
                          </span>
                        );
                      }

                      const startPage = Math.max(0, pageIndex - Math.floor((PAGE_RANGE_DISPLAYED - 1) / 2));
                      const endPage = Math.min(pageCount - 1, startPage + PAGE_RANGE_DISPLAYED - 1);

                      if (i === startPage - 1 && startPage > 1) {
                        return <span key={`ellipsis-start`} className="pagination-btn">...</span>;
                      }

                      if (i >= startPage && i <= endPage) {

                        return (
                          <span
                            key={i}
                            className={`pagination-btn ${isActive ? 'active' : ''}`}
                            onClick={() => { gotoPage(i); setPageNO(i); }}
                          >
                            {i + 1}
                          </span>
                        );
                      }

                      if (i === endPage + 1 && endPage < pageCount - 1 && pageCount > PAGE_RANGE_DISPLAYED && pageIndex !== pageCount - 1 && pageIndex !== 0 && pageCount - pageIndex > 1) {
                        
                        return <span key={`ellipsis-end`} className="pagination-btn">...</span>;
                      }

                      return null;
                    })}

                    {pageIndex !== pageCount - 1 && pageIndex !== pageCount - 2 && pageIndex !== 0 && pageCount > PAGE_RANGE_DISPLAYED && (
                      <span className="pagination-btn" onClick={() => { gotoPage(pageCount - 1); setPageNO(pageCount - 1); }}>
                        {pageCount}
                      </span>
                    )}

                    <button
                      onClick={() => nextPage()} disabled={!canNextPage}
                      className={`btn-next btn-sm btn-falcon-default  ${!canNextPage && 'disabled'
                        }`}
                    >
                      {t('visitorLogout.pagination.paginationbtn')}

                    </button>
                  </div>


                </div>

              )}
            </div>
          </>
        )
      } */}


      {
        ispaginationvisible && (
          <>
            <div className={`paginationinfo ${innerTableSearch}`}>
              <p style={{ color: "#939aac" }}>
                {t('visitorLogout.search.showing')} {pageIndex * pageSize + 1} {t('visitorLogout.search.to')} {' '}
                {Math.min((pageIndex + 1) * pageSize, data.length)} {t('visitorLogout.search.of')} {data.length} {t('visitorLogout.search.entries')}
              </p>
            </div>

            <div className={`main-pagination ${innerTableSearch}`}>
              <div className="d-flex flex-wrap align-items-center">

              </div>
              {!canPreviousPage && !canNextPage && isPaginationRequired ? null : (
                <div className="d-flex flex-wrap">


                  <div className="d-flex pagination">
                    <button
                      type='button'
                      onClick={() => { previousPage(); setPageNO(pageIndex - 1) }}
                      className={`btn-previous btn-sm btn-falcon-default  ${!canPreviousPage && 'disabled'
                        }`}

                    >
                      {t('visitorLogout.pagination.paginationBtn')}

                    </button>


                    {Array.from({ length: pageCount }, (_, i) => {
                      const isActive = i === pageIndex;
                      if (pageCount <= 3) {
                        return (
                          <span
                            key={i}
                            className={`pagination-btn ${isActive ? 'active' : ''}`}
                            onClick={() => { gotoPage(i); setPageNO(i); }}
                          >
                            {i + 1}
                          </span>
                        );
                      }
                      const startPage = Math.max(0, pageIndex - Math.floor((PAGE_RANGE_DISPLAYED - 1) / 2));
                      const endPage = Math.min(pageCount - 1, startPage + PAGE_RANGE_DISPLAYED - 1);

                      if (i === startPage - 1 && startPage > 1) {
                        return <span key={`ellipsis-start`} className="pagination-btn">...</span>;
                      }

                      if (i >= startPage && i <= endPage) {
                        return (
                          <span
                            key={i}
                            className={`pagination-btn ${isActive ? 'active' : ''}`}
                            onClick={() => { gotoPage(i); setPageNO(i); }}
                          >
                            {i + 1}
                          </span>
                        );
                      }

                      if (i === endPage + 1 && endPage < pageCount - 1 && pageCount > PAGE_RANGE_DISPLAYED && pageIndex !== pageCount - 1 && pageIndex !== 0 && pageCount - pageIndex > 1) {
                        return <span key={`ellipsis-end`} className="pagination-btn">...</span>;
                      }

                      return null;
                    })}

                    {pageIndex !== pageCount - 1 && pageIndex !== pageCount - 2 && pageIndex !== 0 && pageCount > PAGE_RANGE_DISPLAYED && (
                      <span className="pagination-btn" onClick={() => { gotoPage(pageCount - 1); setPageNO(pageCount - 1); }}>
                        {pageCount}
                      </span>
                    )}


                    <button
                      type='button'
                      onClick={() => { nextPage(); setPageNO(pageIndex + 1) }} disabled={!canNextPage}
                      className={`btn-next btn-sm btn-falcon-default  ${!canNextPage && 'disabled'
                        }`}
                    >
                      {t('visitorLogout.pagination.paginationbtn')}

                    </button>
                  </div>


                </div>

              )}
            </div>
          </>
        )
      }



      {/* <div className={`paginationinfo ${innerTableSearch}`}>
        <p style={{ color: "#939aac" }}>
          {t('visitorLogout.search.showing')} {pageIndex * pageSize + 1} {t('visitorLogout.search.to')} {' '}
          {Math.min((pageIndex + 1) * pageSize, data.length)} {t('visitorLogout.search.of')} {data.length} {t('visitorLogout.search.entries')}
        </p>
      </div> */}

      {/* <div className={`main-pagination ${innerTableSearch}`}>
        <div className="d-flex flex-wrap align-items-center"> */}

      {/* {isPageNumberIndecatorRequired && (
            <div className="me-3 d-flex flex-wrap align-items-center">
              <span>
                Page{' '}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{' '}
              </span>
            </div>
          )} */}
      {/* {isPageSizeDropdownRequired && (
            <div>
              <div className="d-flex flex-wrap align-items-center">
                <div className="me-2">Page size : </div>
                <div>
                  <select
                    className="form-select"
                    value={pageSize}
                    onChange={e => {
                      setPageSize(Number(e.target.value));
                    }}
                  >
                    {pageSizeOption.map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          )} */}
      {/* </div>
        {!canPreviousPage && !canNextPage && isPaginationRequired ? null : (
          <div className="d-flex flex-wrap"> */}

      {/* <div
              onClick={() => gotoPage(0)}
              className={`px-3 py-2 border rounded pagination-btn ${
                !canPreviousPage && 'disabled'
              }`}
              title="Go to first page"
            >
              <span>{'<<'}</span>
            </div>{' '} */}
      {/* <div className="d-flex pagination">
              <button
                onClick={() => previousPage()}
                className={`btn-previous btn-sm btn-falcon-default  ${!canPreviousPage && 'disabled'
                  }`} */}
      {/* // title="Go to first page"
              > */}
      {/* {t('visitorLogout.pagination.paginationBtn')} */}
      {/* <FontAwesomeIcon icon="chevron-left" /> */}
      {/* </button>
              <button className='pagination-btn'>
                {pageIndex + 1}
              </button> */}
      {/* <ul className="d-flex m-0 p-0">
                {pageOptions.map(totalPage => (
                  <li className="pagination-li" key={totalPage}>
                    <button
                      className={`pagination-btn btn btn-falcon-default mx-1 ${pageIndex === totalPage
                        ? 'active-btn'
                        : 'pagination-btn-hover'
                        }`}
                      onClick={() => gotoPage(totalPage)}
                    >
                      {totalPage + 1}
                    </button>
                  </li>
                ))}
              </ul> */}
      {/* <button
                onClick={() => nextPage()} disabled={!canNextPage}
                className={`btn-next btn-sm btn-falcon-default  ${!canNextPage && 'disabled'
                  }`} */}
      {/* // title="Next"
              > */}
      {/* {t('visitorLogout.pagination.paginationbtn')} */}
      {/* <FontAwesomeIcon icon="chevron-right" /> */}
      {/* </button>
            </div> */}

      {/* <div
              onClick={() => gotoPage(pageCount - 1)}
              className={`px-3 py-2 border rounded pagination-btn ${
                !canNextPage && 'disabled'
              }`}
              title="Go to last page"
            >
              <span>{'>>'}</span>
            </div> */}
      {/* </div>

        )}
      </div> */}

    </>
  );
}
ReactTable.defaultProps = {
  pageSizeOption: [5, 10, 15, 20],
  isPaginationRequired: true,
  isPageNumberIndecatorRequired: true,
  isPageSizeDropdownRequired: true,
  isSearchBoxVisible: true,
  showHeader: true,
  setPageNO: () => { }
};
