export const genderOptions = Object.freeze([
  { value: 'MALE', label: 'Male' },
  { value: 'FEMALE', label: 'Female' },
  { value: 'OTHER', label: 'Other' }
]);

export const destinationOptions = Object.freeze([
  { value: 1, label: "Pink Room" },
  { value: 2, label: "Blue Room" },
  { value: 3, label: "Tennis Court" },
  { value: 4, label: "GYM" },
  { value: 5, label: "AMBOSELI Room" },

])