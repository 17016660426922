import httpServices from './httpServices';
import { adminEndPoints } from './config';

//manage group visits api
export const getGroupVisits = async () => {
  return await httpServices.get(adminEndPoints.getGroupVisits);
};

//
export const getGroupData = async id => {
  return await httpServices.get(`${adminEndPoints.getGroupData}/${id}`);
};

//appointment api

export const getAppointments = async id => {
  return await httpServices.get(adminEndPoints.getAppointments, id);
};
export const getAppointment = async appointmentId => {
  return await httpServices.get(
    `${adminEndPoints.getAppointment}/${appointmentId}`
  );
};
