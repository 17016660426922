export default {
  navbar: {
    logo: {
      logoName: 'MUTHAIGA COUNTRY CLUB'
    },
    profile: {
      logout: 'Logout',
      profilename: 'Bhoomika'
    },
    navbarItems: {
      guestRegistration: 'Guest Registration',
      bookings: 'Guest Bookings',
      visitorRegistration: 'Visitor Registration',
      visitorAppointments: 'Visitor Appointments',
      events: 'Events',
      logout: 'Logout'
    }
  },
  footer: {
    description:
      'Secuviz Visitor Management System, a subsidiary of Riana Group'
  },
  table: {
    dataNotFound: 'No data available.'
  },
  guestRegistration: {
    editGuestModal: {
      heading: "Edit Guest Details",
      cancel: "Cancel",
      update: "Update"
    },
    memberVerification: {
      title: "Member's Verification",
      memberSummary: "Member's Summary",
      guestssummary: "Guests Summary",
      success: "Member verified successfully.",
      label: {
        numberName: "Membership number/name",
        name: "Member Name",
        number: "Member Number",
        membersProfile: "Member's Profile",
        guests: "Number Of Guests",
        memberstatus: "Member Status"
      },
      placeholder: {
        numberName: "Enter Membership name/number"
      },
      error: {
        memberShipNameNumberReq: "Membership number/name is required.",
        memberShipNameNumberInvalid: "Please enter valid Membership number/name."
      },
      verify: "Verify"
    },
    guestVerification: {
      title: "Guest's Verification",
      newEntry: "Create New Entry",
      guestInfo: "Guest Information",
      or: "OR",
      label: {
        waNumber: "Guests WhatsApp Number",
        idType: "Select ID Type",
        docId: "Document ID Number",
        nationality: "Nationality",
        capturePhoto: "Capture Photo",
        frontSide: "Scan Front Side",
        backSide: "Scan Back Side",
        scanDoc: "Scan Document",
        name: "Guest Full Name",
        dob: "Date Of Birth",
        nationality: "Nationality",
        gender: "Gender",
        destination: "Destination",
        vehicleRegistration: "Vehicle Registration No.",
        accessCard: "Access Card No.",
        otp: "OTP",
        sentOtp: "Send OTP",
        resentOtp: "Resend OTP",
        resendOtp: 'Resend OTP',
        verifyAccessCard: "Verify Card",
        addGuest: "Add Guest",
        submit: "Submit"
      },
      placeholder: {
        waNumber: "Enter WhatsApp Number",
        email: "Enter E-mail",
        dob: "dd-mm-yyyy",
        selectdestination: "Select Destination"
      },
      error: {
        emailReq: "Please enter visitors Mobile No. or their email, either field is required",
        idType: "ID Type is required.",
        guestContactNoReq: "Guest's WhatsApp Number or E-mail is required.",
        phoneNoInvalid: "Please enter valid visitor's mobile number.",
        emailInvalid: "Please enter valid visitor's email.",
        docId: "Document ID Number is required.",
        docIdInvalid: "Please enter valid Document Id Number.",
        nationality: "Nationality is required.",
        nationalityInvalid: "Please enter valid Nationality.",
        name: "Guest Full Name is required.",
        validName: "Please enter valid Full Name.",
        dob: "Date Of Birth is required.",
        gender: "Gender is required.",
        nationality: "Nationality is required.",
        destination: "Destination is required.",
        otp: "OTP is required.",
        validOtp: "Please enter valid OTP.",
        accesscard: "Access Card is required.",
        accesscardinvalid: 'Please enter valid Access Card No.',
        vehicle: 'Vehicle Registration No. is required.',
        vehicleinvalid: 'Please enter valid Vehicle Registration No.',
      }
    },
    guestDetails: {
      title: "Guest Details",
      label: {
        no: "No",
        name: "Guest Name",
        number: "Guest Mobile No.",
        guestemailaddress: "Guest Email Address",
        guestidentificationno: "Guest Identification No.",
        action: "Action"
      }
    }
  },
  homePage: {
    heading: 'Visitor Managment System',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam dignissimos, unde quasi adipisci consectetur sunt enim temporibus.',
    loginBtnLabel: 'Login'
  },
  loginPage: {
    heading: 'Log in',
    description: 'Please login to continue using our website.'
  },
  forgotPasswordPage: {
    heading: 'Forgot Password',
    description: "Enter your Email and we'll sent an OTP."
  },
  passwordResetPage: {
    heading: 'Reset Password'
  },
  verifyOtpPage: {
    heading: 'Verify OTP',
    description: 'Enter your OTP.'
  },
  loginFormError: {
    emailRequired: 'Email is required.',
    emailPattern: 'Please enter valid email.',
    passwordRequired: 'Password is required.'
  },
  loginFormLabel: {
    emailLabel: 'Email address',
    passwordLabel: 'Password',
    loginbtnLabel: 'Log in',
    forgotPasswordLinkLabel: 'Forgot Password?'
  },
  loginFormPlaceholder: {
    emailPlaceholder: 'Email address',
    passwordPlaceholder: 'Password'
  },
  forgotPasswordFormError: {
    phoneNoRequired: 'Phone number is required.',
    phoneNoPattern: 'Please enter valid phone number.'
  },
  forgotPasswordFormLabel: {
    emailLabel: 'Enter Your Email',
    sendOtpBtnLabel: 'Send OTP'
  },
  forgotPasswordFormPlaceholder: {
    phoneNoPlaceholder: 'Enter your phone number'
  },
  passwordResetFormError: {
    passwordRequired: 'Password is required.',
    passwordPattern: 'Please enter valid password.',
    confirmPasswordRequired: 'Confirm Password is required.',
    confirmPasswordPattern: 'Please enter valid confirm password.'
  },
  passwordResetFormLabel: {
    passwordLabel: 'New Password',
    confirmPasswordLabel: 'Confirm Password',
    setPasswordBtnLabel: 'Set Password'
  },
  passwordResetFormPlaceholder: {
    passwordPlaceholder: 'New Password',
    confirmPasswordPlaceholder: 'Confirm Password'
  },
  verifyOtpFormError: {
    otpRequired: 'OTP is required.',
    otpPattern: 'Please enter valid OTP.'
  },
  verifyOtpFormLabel: {
    otpLabel: 'Enter OTP',
    verifyOtpLabel: 'Verify OTP'
  },
  verifyOtpFormPlaceholder: {
    otpPlaceholder: 'Enter your OTP'
  },
  visitorRegistration: {
    headingVerification: 'Visitor Verification',
    headingCreateVisitor: 'Create New Entry',
    headingVisitorInformation: 'Visitor Information',
    forOfficePurpose: 'For Office Purpose',
    label: {
      visitorPhoneNo: 'Visitor Mobile No.',
      sendOtpBtn: 'Send OTP',
      bypass: 'By Pass',
      capturePhoto: 'Capture Photo',
      scanfrontDoc: 'Scan Front Document',
      scanbackDoc: 'Scan Back Document',
      scanDoc: "Scan Document",
      nationalId: 'National ID',
      alienId: 'Alien ID',
      passport: 'Passport',
      drivingLicense: 'Driving License',
      companyId: 'Company ID',
      other: 'Other Documents',
      docIdNumber: 'Document ID No.',
      nationality: 'Nationality',
      fullName: 'Full Name',
      dob: 'Date Of Birth',
      gender: 'Gender',
      visitorType: 'Visitor Type',
      lpoNumber: "LPO Number",
      companyFrom: "Company From",
      visitorFrom: 'Visitor From',
      tower: 'Tower',
      company: 'Company',
      department: 'Department',
      employeeName: "Employee Name",
      host: 'Host',
      purpose: 'Purpose',
      accessCardNo: 'Access Card Number',
      numberOfPax: 'Number of PAX',
      vrn: 'Vehicle Registration Number',
      assetDetails: 'Asset Details',
      addAssetBtn: 'Add Assets',
      typeOfAssets: 'Type Of Assets',
      make: 'Make',
      serialNo: 'Serial No.',
      remark: 'Remarks',
      verifyCard: 'Verify Card',
      bioMetric: 'Biometric',
      facialRecognition: 'Facial Recognition',
      save: 'Save',
      submit: 'Submit',
      reset: 'Reset',
      add: 'Add',
      edit: 'Edit',
      cancel: 'Cancel',
      emSave: 'Save',
      addAsset: 'Add Asset',
      editAsset: 'Edit Asset',
      enterOTP: 'Enter OTP',
      otp: 'OTP',
      byPass: 'By Pass',
      bpManagerLogin: 'Manager Login',
      bpEmail: 'Email',
      bpPassword: 'Password',
      bpClose: 'Close',
      bpLogin: 'Login',
      update: 'Update'
    },
    assetTableLabel: {
      typeOfAsset: 'Type Of Assets',
      make: 'Make',
      serialNo: 'Serial Number',
      remark: 'Remark',
      action: 'Action'
    },
    placeholder: {
      dob: 'dd-mm-yyyy',
      gender: '',
      visitorType: 'Select visitor Type',
      tower: '',
      company: '',
      department: 'Select Department',
      whatsAppNo: "Enter Mobile No.",
      email: "Enter E-mail",
      employeeName: "",
      host: '',
      purpose: '',
      accessCardNo: '',
      numberOfPax: '',
      vrn: '',
      assetDetails: '',
      typeOfAssets: '',
      make: '',
      serialNo: '',
      remark: '',
      selectdestination: 'Select Destination',
      employeename: "Select Employee Name"
    },
    error: {
      emailReq: "Please enter visitors Mobile No. or their email, either field is required",
      lpoWarning: "if the visitor type for Supplier is selected another field for 'LPO Number' should render",
      lpoNumberReq: "LPO Number is required.",
      selectidtype: 'Please select ID Type',
      phoneNoReq: "Visitor's mobile number is required.",
      phoneNoInvalid: "Please enter valid visitor's mobile number.",
      emailInvalid: "Please enter valid visitor's Email.",
      identityTypeReq: 'Please enter Mobile No.',
      documentIdNumber: 'Document ID No. is required.',
      documentIdNumberInvalid: "Please enter valid Document Id Number.",
      nationality: 'Nationality is required.',
      nationalityInvalid: "Please enter valid Nationality.",
      fullNameReq: 'Full Name is required.',
      fullNameInvalid: 'Please enter valid Full Name.',
      birthDateReq: 'Date Of Birth is required.',
      genderReq: "Select Gender.",
      visitorTypeReq: 'Select Visitor Type.',
      visitorFromReq: 'Visitor From is required.',
      visitorFromInvalid: 'Please enter valid Visitor From.',
      employeeNameReq: "Select Employee Name.",
      employeeNameInvalid: "Please enter valid Employee Name.",
      companyFromReq: "Company From is required.",
      companyFromInvalid: "Please enter valid Company From.",
      towerReq: 'Select tower name.',
      selectCompanyMsg: 'Please select tower first.',
      dropdownError: 'No options',
      companyReq: 'Select company name.',
      selectDepartmentMsg: 'Please select company first.',
      selectHostError: 'Please select company and department first.',
      departmentReq: 'Select Department.',
      hostReq: 'Select host name.',
      purposeReq: 'Purpose is required.',
      accessCardNoReq: 'Access Card Number is required.',
      accessCardNoInvalid: "Please enter valid Access Card Number.",
      numberOfPaxReq: 'Number of pax is required.',
      vrnReq: 'Vehicle registration number is required.',
      assetTypeReq: 'Select asset type.',
      makeReq: 'Make is required.',
      serialNoReq: 'Serial number is required.',
      remarkReq: 'Remark is required.',
      noAssetsAdded: 'No asset added.',
      enterOTP: 'OTP is required.',
      otpPattern: 'Please enter valid OTP.',
      bpEmail: 'Email is required.',
      bpEmailPattern: 'Please enter valid email.',
      bpPassword: 'Password is required.',
      bpPasswordPattern: 'Please enter valid password.',
      destination: 'Destination is required.',
      noOptions: "No options",
      selectDept: "Please select department first"
    },
    sweetAlert: {
      confirmationTitle: 'Are you sure?',
      confirmationText: "You won't be able to revert this.",
      confirmationBtnLabel: 'Yes, delete it.',
      simpleTitle: 'Deleted.',
      simpleText: 'Asset has been deleted.',
      bpconfirmationTitle: 'Are you sure you want to bypass?',
      bpBtnYes: 'Yes',
      bpBtnNo: 'No'
    }
  },
  visitorLogout: {
    heading: {
      visitorLog: 'Visitor Log'
    },
    tableHead: {
      sNo: 'No',
      visitorName: 'Visitor Name',
      accessCardNo: 'Access Card No',
      visitorType: 'Visitor Type',
      timeIn: 'Time In',
      timeOut: 'Time Out'
    },
    btnLabel: {
      logout: 'Logout'
    },
    label: {
      totalIn: 'Total In',
      totalOut: 'Total Out',
      todaysVisitor: "Today's Total visitors"
    },
    search: {
      search: 'Search:',
      show: 'Show',
      entries: 'entries',
      showing: 'Showing',
      to: 'to',
      of: 'of',
    },
    pagination: {
      paginationBtn: 'Previous',
      paginationbtn: 'Next'
    },
  },
  appointment: {
    heading: {
      appointment: "Today's Appointments"
    },
    tableHead: {
      sNo: 'S.no',
      details: 'Details',
      date: 'Date',
      appointmentTime: 'Appointment Time',
      action: 'Action'
    },
    btnLabel: {
      register: 'Register'
    }
  },
  groupVisit: {
    heading: {
      groupVisit: 'Group Visit'
    },
    tableHead: {
      dateAndTime: 'Date and Time',
      groupName: 'Group Name',
      organization: 'Organization',
      leader: 'Leader	',
      purpose: 'Purpose',
      host: 'Host',
      company: 'Company',
      action: 'Action'
    },
    btnLabel: {
      viewGroup: 'View Group'
    },
    groupDataModal: {
      heading: {
        groupData: 'Group Visit'
      },
      tableHead: {
        sNo: 'S.no',
        groupMembers: '	Group Members',
        action: 'Action'
      },
      btnLabel: {
        login: 'Login',
        logout: 'Logout',
        save: 'Save',
        sendOTP: 'Send OTP',
        verifyOTP: 'Verify OTP'
      },
      formLabel: {
        verifyLeaderMobile: 'Verify Leader Mobile',
        otp: 'OTP',
        capturePhoto: 'Capture Photo',
        scanfrontDoc: 'Scan Front Document',
        scanbackDoc: 'Scan Back Document',
        scanDoc: "Scan Document",
        nationalId: 'National ID',
        alienId: 'Alien ID',
        passport: 'Passport',
        drivingLicense: 'Driving License',
        companyId: 'Company ID',
        other: 'Other Documents',
        docIdNumber: 'Document Id Number',
        nationality: 'Nationality'
      },
      error: {
        contactNoError: "Leader's mobile number is required.",
        contactNoValidationsError: 'Please enter valid Leader mobile number.',
        docTypeError: 'Select any one of them.',
        docIdNoError: 'Document ID No. is required.',
        nationalityError: 'Nationality is required.',
        enterOTP: 'OTP is required.',
        otpPattern: 'Please enter valid OTP.',
      }
    }
  },
  event: {
    tableheading: 'Events',
    id: 'ID',
    dateandtime: 'Date and Time',
    eventname: 'Event Name',
    member: 'Member',
    destination: 'Destination',
    action: 'Action'
  },
  bookings: {
    tableheading: "Today's Booking's",
    no: 'No',
    guestname: 'Guest Name',
    invitedby: 'Invited By',
    bookingtime: 'Booking Time',
    action: 'Action',
    btnregister: 'Register',
    bookingsmodal: {
      modalheading: 'Guest Verification',
      destination: 'Destination',
      accesscardno: 'Access Card No.',
      otp: 'OTP',
      submitbtn: 'Submit',
      accessfield: 'Enter Access Card No.',
      otpfield: 'Enter OTP'
    },
    registerguestbooking: {
      registerguestbookingheading: 'Register Guest Booking'
    },
    label: {
      idtype: 'Select ID Type',
      capturePhoto: 'Capture Photo',
      scanfrontDoc: 'Scan Front Document',
      scanbackDoc: 'Scan Back Document',
      scanDoc: "Scan Document",
      submit: 'Submit',
      reset: 'Reset',
      otp: 'OTP',
      destination: 'Destination',
      gender: 'Gender',
      dob: 'Date Of Birth',
      fullname: 'Full Name',
      nationality: 'Nationality',
      docidnumber: 'Document ID Number',
    },
    error: {
      accesserrorrequired: "Access Card No. is required.",
      accesserrorpattern: "Please enter valid Access Card No.",
      doctypeerror: 'Please select ID Type.',
      otprequired: "OTP is required.",
      otppattern: "Please enter valid OTP.",
      documentIdNumber: 'Document ID Number is required.',
      documentIdNumberInvalid: "Please enter valid Document Id Number.",
      nationality: 'Nationality is required.',
      nationalityInvalid: "Please enter valid Nationality.",
      fullNameReq: 'Full Name is required.',
      fullNameInvalid: 'Please enter valid Full Name.',
      birthDateReq: 'Date Of Birth is required.',
      genderReq: "Please select Gender.",
      destination: 'Please select Destination.'
    },
    placeholder: {
      selectdestination: 'Select Destination'
    }
  },
  nestedtable: {
    searchbox: 'Search by name',
    tableheading: "Guest List",
    no: "No",
    name: "Name",
    timein: "Time In",
    action: "Action",
    arrived: "Arrived",
    markarrived: "Mark Arrived"
  },
  visitorappointments: {
    tableheading: "Today's Appointments",
    no: 'No',
    visitorname: 'Visitor Name',
    hostname: 'Host Name',
    appointmentdate: 'Appointment Date',
    appointmenttime: 'Appointment Time',
    action: 'Action'
  }
};
