import React from 'react';
import { useTranslation } from 'react-i18next';
import PasswordResetForm from 'components/authentication/PasswordResetForm';
import Logo from "../../../assets/logo/Centenary_Logo.png"
import "../css/forgot-password.css"

const PasswordReset = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="d-flex flex-column align-items-center mb-2">
        <img src={Logo} className='company-logo' />
        <h2 className="mb-2 mt-4">{t("passwordResetPage.heading")}</h2>
      </div>
      <PasswordResetForm />
    </div>
  )
};

export default PasswordReset;
