import axios from 'axios';

axios.interceptors.response.use(null, error => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (error.response.status == 401) {
    localStorage.removeItem('authToken');
    window.location.replace('/login');
    return Promise.reject(error);
  } else if (!expectedError) {
    return Promise.reject(error);
  } else {
    return Promise.reject(error);
  }
});
axios.interceptors.request.use(
  (request) => {
    const authToken = localStorage.getItem("authToken")
    request.headers['Authorization'] = `Bearer ${authToken}`;
    // request.headers['Content-Type'] = "application/json";
    request.headers['Accept'] = "application/json";
    // request.headers['Access-Control-Allow-Headers'] = "*"
    return request;
  }
);
export default {
  get: axios.get,
  post: axios.post,
  delete: axios.delete,
  patch: axios.patch,
  put: axios.put
};
