import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { Modal } from 'react-bootstrap';
import Webcam from 'react-webcam';
import "../../../src/assets/css/capture.css"


export default function CaptureDocumentModal({ isModalOpen, setIsModalOpen, setUserPhoto }) {
    const videoConstraints = {
        facingMode: "environment"
    };
    const webcamRef = React.useRef(null);
    const capture = React.useCallback(async () => {
        const imageSrc = webcamRef.current.getScreenshot();
        setUserPhoto(imageSrc)
        setIsModalOpen(false)
    }, [webcamRef]);
    return (
        <Modal show={isModalOpen} fullscreen={true} onHide={() => setIsModalOpen(false)} style={{ backgroundColor: "none" }}>
            <Modal.Body className='modalbody' style={{ height: "100%", backgroundColor: "none" }}>
                <div className='position-absolute' style={{ right: "0%" }}>
                    <i closeButton className="fa  fa-times-circle modal-close-btn" aria-hidden="true" onClick={() => setIsModalOpen(false)}></i>
                </div>
                <div className='d-flex align-items-center justify-content-center' style={{ flexDirection: "column", height: "inherit" }}>
                    <div style={{ display: "block" }}>
                        <Webcam
                            audio={false}
                            style={{ height: "34.81rem", maxWidth: "100%" }}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            videoConstraints={videoConstraints}
                        // mirrored={true}
                        />
                    </div>
                    <div className="capture-cropper">

                    </div>
                    <button className="btn btn-primary" onClick={capture} style={{ position: "absolute", bottom: "15%" }}>
                        Take Snapshot
                    </button>
                </div>
            </Modal.Body>
            {/* </div> */}
        </Modal>
    )
}
