import { faAddressBook } from '@fortawesome/free-solid-svg-icons';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

export const navbar = {
  label: 'nav-items',
  labelDisable: true,
  children: [
    {
      name: 'navbar.navbarItems.guestRegistration',
      to: '/guest-registration',
      active: true,
      icon: 'user-plus'
    },
    {
      name: 'navbar.navbarItems.bookings',
      to: '/bookings',
      active: true,
      icon: faAddressBook
    },
    {
      name: 'navbar.navbarItems.visitorRegistration',
      to: '/visitor-registration',
      active: true,
      icon: 'user-plus'
    },
    {
      name: 'navbar.navbarItems.visitorAppointments',
      to: '/visitor-appointments',
      active: true,
      icon: 'user-plus'
    },
    {
      name: 'navbar.navbarItems.events',
      to: '/events',
      active: true,
      icon: faAddressBook
    },
    {
      name: 'navbar.navbarItems.logout',
      to: '/visitor-logout',
      active: true,
      icon: faSignOutAlt
    },
  ]
};

export default [navbar];
