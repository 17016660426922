export default {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  phoneNumber: /^\d+$/,
  otp: /^\d+$/,
  password: /^\d+$/,
  numberAndAlphabet: /^[a-zA-Z0-9 _.-]*$/,
  numberOnly: /^\d+$/,
  alphabetWithSpace: /^[a-zA-Z ]*$/,
  IPv4: /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/,
  visitorName: /^[a-zA-Z ]*$/,
  designation: /^[a-zA-Z ]*$/,
  fullName: /^[^-\s][a-zA-Z_\s-]*$/,
  notAllowSpaceInStart: /^[^-\s][a-zA-Z0-9_\s-]+$/
  // notAllowSpaceInStart: /^[^\s][a-zA-Z0-9][a-zA-Z0-9-_]*$/
};
