import React from 'react';
import DatePicker from 'react-datepicker';
import '../../../src/assets/css/datepicker.css';

export default function ReactDatePicker({
  value,
  onChange,
  hasErrors,
  placeholder,
  disabled,
  dateFormat,
  maxDate,
  formFieldsDisabled,
}) {
  const isDisabled = formFieldsDisabled;
  return (
    <div className={`position-relative form-control   ${hasErrors && 'is-invalid'} ${isDisabled && 'date-disabled'}`}>
      <DatePicker
        value={value}
        selected={value}
        onChange={e => onChange(e)}
        formatWeekDay={day => day.slice(0, 3)}
        dateFormat={dateFormat}
        className={`col-12 datepicker-input`}
        placeholderText={placeholder}
        disabled={isDisabled}
        maxDate={maxDate}
        onKeyDown={e => {
          e.preventDefault();
        }}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
      />
      <span className={`form-icon ${hasErrors && 'me-4'}`}>
        <svg
          className="svg-inline--fa fa-calendar-alt fa-w-14"
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="calendar-alt"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
          data-fa-i2svg=""
        >
          <path
            fill="currentColor"
            d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z"
          ></path>
        </svg>
      </span>
    </div>
  );
}
ReactDatePicker.defaultProps = {
  disabled: false,
  dateFormat: 'MM/dd/yyyy',
  hasErrors: false
};
