import React, { useContext, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigation } from 'react-router-dom';
import AuthSimpleLayout from './AuthSimpleLayout';
import is from 'is_js';
import MainLayout from './MainLayout';
// import About from '../components/pages/about/About';
// import Home from '../components/pages/home/Home';
import ErrorLayout from './ErrorLayout';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';
import FontAwesome from 'components/doc-components/FontAwesome';
import Calendar from 'components/app/calendar/Calendar';
import ReactIcons from 'components/doc-components/ReactIcons';
import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';
import SimpleLogin from 'components/authentication/simple/Login';
import SimpleForgetPassword from 'components/authentication/simple/ForgetPassword';
import SimplePasswordReset from 'components/authentication/simple/PasswordReset';
// import Dashboard from 'components/dashboards/default';
import AppContext from 'context/Context';
import VisitorLogout from 'components/pages/visitorLogout/VisitorLogout';
import VisitorRegistration from 'components/pages/visitorRegistration/VisitorRegistration';
// import Appointments from 'components/pages/appointments/Appointments';
// import GroupVisit from 'components/pages/groupVisit/GroupVisit';

// import AppointmentRegistration from 'components/pages/visitorRegistration/appointmentRegistration';
import AuthenticateRoute from 'components/common/AuthenticateRoute';
import AuthenticateAuthRoute from 'components/common/AuthenticateAuthRoute';
import VerifyOtp from 'components/authentication/simple/VerifyOtp';
import HomepageLayout from './HomepageLayout';
import Bookings from 'components/pages/bookings/Bookings';
import Events from 'components/pages/events/Events';
import GuestRegistration from 'components/pages/guestregistration/GuestRegistration';
import Home from 'components/pages/home/Home';
import VisitorAppointments from 'components/pages/visitorappointments/VisitorAppointments';
import Loader from 'components/common/Loader';
import { loaderContext } from 'context/Context';
import axios from 'axios';
// import RegisterGuestBooking from 'components/pages/bookings/RegisterGuestBooking';

const Layout = () => {
  const [isLoading, setIsLoading] = useState(false)
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  return (
    <>
      {isLoading && <Loader />}
      <loaderContext.Provider value={{ isLoading, setIsLoading }}>
        <Routes>
          <Route element={<AuthenticateRoute />}>
            <Route element={<ErrorLayout />}>
              <Route path="errors/404" element={<Error404 />} />
              <Route path="errors/500" element={<Error500 />} />
            </Route>

            {/* //--- MainLayout Starts  */}

            <Route element={<MainLayout />}>
              {/*Dashboard*/}
              {/* <Route path="/" element={<Dashboard />} />
          <Route path="home" element={<Home />} />
          <Route path="about" element={<About />} /> */}
              <Route path="/guest-registration" element={<GuestRegistration />} />
              <Route path="/visitor-logout" element={<VisitorLogout />} />
              <Route path="/visitor-appointments" element={<VisitorAppointments />} />
              <Route path="/bookings" element={<Bookings />} />
              {/* <Route path="/registerguestbooking" element={<RegisterGuestBooking />} /> */}
              <Route path="/events" element={<Events />} />
              <Route
                path="visitor-registration"
                element={<VisitorRegistration />}
              />
              <Route
                path="visitor-registration/:id"
                element={<VisitorRegistration />}
              />
              {/* <Route path="appointments" element={<Appointments />} />
            <Route path="group-visit" element={<GroupVisit />} /> */}
              {/*icons*/}
              <Route path="icons/font-awesome" element={<FontAwesome />} />
              <Route path="icons/react-icons" element={<ReactIcons />} />

              {/*App*/}
              <Route path="app/calendar" element={<Calendar />} />
            </Route>
          </Route>
          {/* //--- MainLayout end  */}
          {/*- ------------- Authentication ---------------------------  */}

          {/*- ------------- simple ---------------------------  */}
          <Route element={<AuthenticateAuthRoute />}>
            <Route element={<AuthSimpleLayout />}>
              <Route path="login" element={<SimpleLogin />} />
              <Route path="forgot-password" element={<SimpleForgetPassword />} />
              <Route path="reset-password" element={<SimplePasswordReset />} />
              <Route path="verify-otp" element={<VerifyOtp />} />
            </Route>
            <Route element={<HomepageLayout />}>
              <Route path="/" element={<Home />} />
            </Route>
            <Route path="*" element={<Navigate to="/login" replace />} />
          </Route>

          {/* <Navigate to="/errors/404" /> */}
          {/* <Route path="*" element={<Navigate to="/errors/404" replace />} /> */}
        </Routes>
      </loaderContext.Provider>
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </>
  );
};

export default Layout;
