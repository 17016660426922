import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import InputPassword from 'components/common/InputPassword';
import toastConfig from 'components/configuration/toast-config';
import pattern from 'components/configuration/form-pattern';
import { resetPassword } from 'services/authService';

const PasswordResetForm = ({ hasLabel }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm();
  const navigate = useNavigate();

  const onSubmit = async formData => {
    try {
      formData.email = location?.state?.email
      formData.otp = location?.state?.otp
      const { data } = await resetPassword(formData)
      toast.success(data?.message, toastConfig)
      navigate("/login", { replace: true })
    } catch (e) {
      if (e.response.data) {
        if (Array.isArray(e.response.data.message)) {
          e.response.data.message.map(errors => toast.error(errors, toastConfig))
        } else {
          toast.error(e.response.data.message, toastConfig)
        }
      } else {
        toast.error(e.message, toastConfig)
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <div className="mb-3 form-group">
        {hasLabel && (
          <div className="form-label">
            {t('passwordResetFormLabel.passwordLabel')}
          </div>
        )}
        <Controller
          name="password"
          control={control}
          rules={{ required: true, minLength: 8 }}
          render={({ field: { value, onChange } }) => (
            <InputPassword
              value={value}
              onChange={onChange}
              isInvalid={errors.password ? true : false}
              maxLength={15}
              placeholder="passwordResetFormPlaceholder.passwordPlaceholder"
            />
          )}
        />
        {errors.password && (
          <small className="text-danger">
            {errors.password.type === 'required' && (
              <span>{t('passwordResetFormError.passwordRequired')}</span>
            )}
            {errors.password.type === 'minLength' && (
              <span>{t('passwordResetFormError.passwordPattern')}</span>
            )}
          </small>
        )}
      </div>

      <div className="mb-3 form-group">
        {hasLabel && (
          <div className="form-label">
            {t('passwordResetFormLabel.confirmPasswordLabel')}
          </div>
        )}

        <Controller
          name="password_confirmation"
          control={control}
          rules={{ required: true, minLength: 8 }}
          render={({ field: { value, onChange } }) => (
            <InputPassword
              value={value}
              onChange={onChange}
              isInvalid={errors.password_confirmation ? true : false}
              maxLength={15}
              placeholder="passwordResetFormPlaceholder.confirmPasswordPlaceholder"
            />
          )}
        />
        {errors.password_confirmation && (
          <small className="text-danger">
            {errors.password_confirmation.type === 'required' && (
              <span>{t('passwordResetFormError.confirmPasswordRequired')}</span>
            )}
            {errors.password_confirmation.type === 'minLength' && (
              <span>{t('passwordResetFormError.confirmPasswordPattern')}</span>
            )}
          </small>
        )}
      </div>

      <Button type="submit" className="w-100 btn-primary btn-lg">
        {t('passwordResetFormLabel.setPasswordBtnLabel')}
      </Button>
    </form>
  );
};

PasswordResetForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default PasswordResetForm;
