import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

export default function SearchBox({ value, onChange, placeholder }) {
  const { t } = useTranslation();
  return (
    // <div className={`d-flex justify-content-between form-control rounded-pill`}>
    //   <div className="d-flex align-items-center " style={{ color: '#b6c1d2' }}>
    //     <FontAwesomeIcon icon="search" />
    //   </div>
    //   <input
    //     className={`border-0 outline-none col-11`}
    //     placeholder="Search by Access card Number, Name"
    //     type="text"
    //     value={value}
    //     onChange={e => onChange(e)}
    //   />
    // </div>

    <div className="d-flex  d-grid  align-items-center searchbox">
      <label className="col-sm-3 col-form-label fw-bold searchtext">
        {t('visitorLogout.search.search')}
      </label>
      <div className='col-sm-9'>
        <input
          className="form-control search-input "
          type="search"
          value={value}
          onChange={e => onChange(e)}
        />
      </div>
    </div>
  );
}
