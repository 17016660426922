import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import TRANSLATION_EN from './components/configuration/statics/en/translation';
import TRANSLATION_GU from './components/configuration/statics/gu/translation';

const fallbackLng = ['en'];
const availableLanguages = ['en', 'bn', 'gu'];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng,
    detection: {
      checkWhitelist: true
    },
    debug: false,
    whitelist: availableLanguages,
    resources: {
      en: {
        translation: TRANSLATION_EN
      },
      gu: {
        translation: TRANSLATION_GU
      }
    }
  });

export default i18n;
