import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { Modal } from 'react-bootstrap'
import pattern from 'components/configuration/form-pattern';
import SearchableDropdown from 'components/common/SearchableDropdown';
import ReactDatePicker from 'components/common/ReactDatePicker';
import moment from 'moment';
import CapturePhotoModal from 'components/common/CapturePhotoModal';
import CaptureDocumentModal from 'components/common/CaptureDocumentModal';
import { commonScanDocEndPoints } from 'services/config';
import axios from 'axios';
import { toast } from 'react-toastify';
import { loaderContext } from 'context/Context';
import { useContext } from 'react';
import { verifyAccessCard } from 'services/commonService';

export default function GuestEditModal({ isModalOpen, setIsModalOpen, guestData, destinations, identitySection, docType, genderOptions, onGuestDataEdit }) {
    const { t } = useTranslation();
    const [isCaptureFrontDocModalOpen, setIsCaptureFrontDocModalOpen] = useState(false)
    const [isCaptureBackDocModalOpen, setIsCaptureBackDocModalOpen] = useState(false)
    const [isCapturePhotoModalOpen, setIsCapturePhotoModalOpen] = useState(false);
    const [userPhoto, setUserPhoto] = useState("");
    const [frontDocPhoto, setFrontDocPhoto] = useState("");
    const [backDocPhoto, setBackDocPhoto] = useState("");
    const { isLoading, setIsLoading } = useContext(loaderContext)
    const isMobile = navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i) ? true : false
    useEffect(() => {
        if (isModalOpen) {
            const editGuestData = { ...guestData }
            editGuestData.destination_id = destinations?.find((destination) => destination?.value === editGuestData?.destination_id)
            editGuestData.gender = genderOptions?.find((gender) => gender?.label === editGuestData?.gender)
            editGuestData.dob = (editGuestData.dob && editGuestData.dob != "") ? moment(editGuestData.dob).toDate() : ""
            setUserPhoto(editGuestData?.profile_photo)
            setFrontDocPhoto(editGuestData?.front)
            setBackDocPhoto(editGuestData?.back)
            reset(editGuestData);
        }
    }, [isModalOpen])

    const getAccessCardNumber = async () => {
        setIsLoading(true)
        try {
            const { data } = await verifyAccessCard({ host_type: 2 })
            setValue("access_card_no", data?.data[0]?.access_card_no)
            clearErrors("access_card_no")
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        control,
        setValue,
        setError,
        clearErrors,
        watch
    } = useForm();
    const documentType = watch("document_type");
    // useEffect(() => {
    //     if (documentType) {
    //         setFrontDocPhoto("");
    //         setBackDocPhoto("");
    //         setValue("name", "")
    //         setValue("document_id_number", "")
    //         setValue("nationality", "")
    //         setValue("dob", "")
    //         setValue("gender", "")
    //     }
    // }, [documentType])
    useEffect(() => {
        if (documentType != "4") {
            if (documentType != "3") {
                if (frontDocPhoto && frontDocPhoto != "" && backDocPhoto && backDocPhoto != "") {
                    getDataFrontDocument()
                }
            } else {
                if (frontDocPhoto && frontDocPhoto != "") {
                    getDataFromPassport()
                }
            }
        }

    }, [frontDocPhoto, backDocPhoto])
    const getDataFrontDocument = async () => {
        setIsLoading(true)
        try {
            const frontImg = frontDocPhoto
            const backImg = backDocPhoto
            const { data } = await axios.post(commonScanDocEndPoints.imageIdentity, { front: frontImg.split(',')[1], back: backImg.split(',')[1], type: documentType }, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            const documentData = data?.data[0]?.document_data;
            setValue("gender", genderOptions.find((gender) => gender.value === documentData?.gender))
            setValue("name", documentData?.name)
            setValue("document_id_number", documentData?.id_card_number)
            setValue("nationality", documentData?.nationality)
            setValue("dob", moment(documentData?.dob, process.env.REACT_APP_DISPLAY_DATE_FORMAT).toDate())
            clearErrors("gender")
            clearErrors("name")
            clearErrors("document_id_number")
            clearErrors("nationality")
            clearErrors("dob")
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }
    const getDataFromPassport = async () => {
        try {
            const frontImg = frontDocPhoto
            const { data } = await axios.post(commonScanDocEndPoints.imageIdentity, { front: frontImg.split(',')[1], type: documentType }, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            const documentData = data?.data[0]?.document_data;
            setValue("gender", genderOptions.find((gender) => gender.value === documentData?.gender))
            setValue("name", documentData?.name)
            setValue("document_id_number", documentData?.id_card_number)
            setValue("nationality", documentData?.nationality)
            { (documentData?.dob && documentData?.dob != "") && setValue("dob", moment(documentData?.dob, process.env.REACT_APP_DISPLAY_DATE_FORMAT).toDate()) }
            clearErrors("gender")
            clearErrors("name")
            clearErrors("document_id_number")
            clearErrors("nationality")
            clearErrors("dob")
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
    }

    const onSubmit = (formData) => {
        formData.destination_id = formData.destination_id.value;
        formData.gender = formData.gender.label;
        formData.dob = moment(formData.dob).format(process.env.REACT_APP_DATE_FORMAT)
        formData.profile_photo = userPhoto
        formData.front = frontDocPhoto;
        formData.back = backDocPhoto;
        onGuestDataEdit(formData)
    }
    const setProfilePhoto = (data) => {
        setUserPhoto(data);
    }
    const setFrontDocImage = (data) => {
        setFrontDocPhoto(data);
    }
    const setBackDocImage = (data) => {
        setBackDocPhoto(data);
    }
    const handleCapture = (target) => {
        if (target.files) {
            if (target.files.length !== 0) {
                const file = target.files[0];
                const newUrl = URL.createObjectURL(file);
                setUserPhoto(newUrl);
            }
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false)
    }

    const frontsideClick = () => {
        if (documentType && documentType != '') {
            setIsCaptureFrontDocModalOpen(true)
        } else {
            toast.error("Please select ID Type first.", toastConfig)
        }
    }
    const backsideClick = () => {
        if (documentType && documentType != '') {
            setIsCaptureBackDocModalOpen(true)
        } else {
            toast.error("Please select ID Type first.", toastConfig)
        }
    }

    return (
        <Modal show={isModalOpen} onHide={() => handleCloseModal()} >
            <Modal.Header className="heading-color fw-600 guestregistermodalheader" >{t('guestRegistration.editGuestModal.heading')}
                <i closeButton className="fa  fa-times-circle modal-close-btn" aria-hidden="true" onClick={() => handleCloseModal()}></i>
            </Modal.Header>
            <Modal.Body className='modalbody'>
                <form
                    encType="multipart/form-data"
                    autoComplete="off"
                >
                    <div className="card-body pt-0">
                        <div className="row nationality-section">
                            {identitySection && <div
                                className="col-md-12 rounded-3"
                            >
                                <div className="documentid-data">
                                    <div className="row align-items-center">
                                        {<div className="col-md-12">
                                            <label className="form-label">
                                                {t('guestRegistration.guestVerification.label.idType')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <div className='form-group mb-3 mb-sm-0'>
                                                {docType.map((docType, index) => (
                                                    <div className="form-check form-check-inline" key={docType.id}>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            value={docType.id}
                                                            {...register('document_type', {
                                                                required: true
                                                            })}
                                                        />
                                                        <label className="form-check-label">
                                                            {docType.documentName}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                            {errors.document_type && (
                                                <small>
                                                    {errors.document_type.type === 'required' && (
                                                        <span className="text-danger">
                                                            {t(
                                                                'guestRegistration.guestVerification.error.idType'
                                                            )}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </div>}
                                    </div>
                                </div>
                            </div>}
                            {isMobile && <input
                                accept="image/*"
                                style={{ display: "none" }}
                                id="icon-button-file"
                                type="file"
                                capture="environment"
                                onChange={(e) => toast.success("Hello")}
                            />}
                        </div>
                        {/* <div className="col-md-6" style={{ marginLeft: "-1rem" }}> */}
                        <div className="row">
                            <div className="col-lg-6 col-xl-4 col-md-6 d-flex justify-content-center">
                                <label htmlFor='icon-button-file'>
                                    <div className="scan-box-visitor" onClick={() => setIsCapturePhotoModalOpen(true)}>
                                        <img
                                            src={`${userPhoto != "" ? userPhoto : "/admin-images/user.png"}`}
                                            style={{ maxWidth: '80px', height: "100%", width: "100%", aspectRatio: "auto" }}
                                        />
                                        <h4 className='scan-text'>{t('guestRegistration.guestVerification.label.capturePhoto')}</h4>
                                    </div>
                                </label>
                            </div>
                            <div className="col-lg-6 col-xl-4 col-md-6 d-flex justify-content-center">
                                <div className="scan-box-visitor" onClick={() => frontsideClick()}>
                                    <img
                                        src={`${frontDocPhoto != "" ? frontDocPhoto : "/admin-images/scanner.png"}`}
                                        className="img-fluid"
                                        style={{ maxWidth: '80px', height: "100%", width: "100%", aspectRatio: "auto" }}
                                    />
                                    <h4 className='scan-text'>{documentType != "3" ? t('guestRegistration.guestVerification.label.frontSide') : t('guestRegistration.guestVerification.label.scanDoc')}</h4>
                                </div>
                            </div>
                            {documentType != "3" && <div className="col-lg-6 col-xl-4 col-md-6 d-flex justify-content-center">
                                <div className="scan-box-visitor" onClick={() => backsideClick()}>
                                    <img
                                        src={`${backDocPhoto != "" ? backDocPhoto : "/admin-images/scanner.png"}`}
                                        className="img-fluid"
                                        style={{ maxWidth: '80px', height: "100%", width: "100%", aspectRatio: "auto" }}
                                    />
                                    <h4 className='scan-text'>{t('guestRegistration.guestVerification.label.backSide')}</h4>
                                </div>
                            </div>}
                        </div>
                        {/* </div> */}


                        <div className="col-12">
                            <hr />
                        </div>
                        <div className="col-md-12 mb-3">
                            <div className="row">
                                <h5 className="mb-3">
                                    {t('guestRegistration.guestVerification.guestInfo')}
                                </h5>
                                <div className="form-group col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-3">
                                    <label>
                                        {t('guestRegistration.guestVerification.label.name')}
                                        <span className="text-danger ms-1">*</span>
                                    </label>
                                    <input
                                        className={`form-control ${errors.name && 'is-invalid'
                                            }`}
                                        type="text"
                                        {...register('name', {
                                            required: true,
                                            pattern: pattern.fullName
                                        })}
                                    />
                                    {errors.name && (
                                        <small>
                                            {errors.name.type === 'required' && (
                                                <span className="text-danger">
                                                    {t('guestRegistration.guestVerification.error.name')}
                                                </span>
                                            )}
                                            {errors.name.type === 'pattern' && (
                                                <span className="text-danger">
                                                    {t('guestRegistration.guestVerification.error.validName')}
                                                </span>
                                            )}
                                        </small>
                                    )}
                                </div>

                                <div className="form-group col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-3">
                                    <label className="form-label">
                                        {t('guestRegistration.guestVerification.label.docId')}
                                        <span className="text-danger ms-1">*</span>
                                    </label>
                                    <input
                                        className={`form-control ${errors.document_id_number && 'is-invalid'
                                            }`}
                                        type="text"
                                        {...register('document_id_number', {
                                            required: true,
                                            pattern: pattern.notAllowSpaceInStart
                                        })}
                                    />
                                    {errors.document_id_number && (
                                        <small>
                                            {errors.document_id_number.type === 'required' && (
                                                <span className="text-danger">
                                                    {t(
                                                        'guestRegistration.guestVerification.error.docId'
                                                    )}
                                                </span>
                                            )}
                                            {errors.document_id_number.type === 'pattern' && (
                                                <span className="text-danger">
                                                    {t(
                                                        'guestRegistration.guestVerification.error.docIdInvalid'
                                                    )}
                                                </span>
                                            )}
                                        </small>
                                    )}
                                </div>
                                <div className="form-group col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-3">
                                    <label className="form-label">
                                        {t('guestRegistration.guestVerification.label.nationality')}
                                        <span className="text-danger ms-1">*</span>
                                    </label>
                                    <input
                                        className={`form-control ${errors.nationality && 'is-invalid'
                                            }`}
                                        type="text"
                                        {...register('nationality', { required: true, pattern: pattern.notAllowSpaceInStart })}
                                    />
                                    {errors.nationality && (
                                        <small>
                                            {errors.nationality.type === 'required' && (
                                                <span className="text-danger">
                                                    {t('guestRegistration.guestVerification.error.nationality')}
                                                </span>
                                            )}
                                            {errors.nationality.type === 'pattern' && (
                                                <span className="text-danger">
                                                    {t('guestRegistration.guestVerification.error.nationalityInvalid')}
                                                </span>
                                            )}
                                        </small>
                                    )}
                                </div>

                                <div className="form-group col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-3">
                                    <label>
                                        {t('guestRegistration.guestVerification.label.dob')}
                                        <span className="text-danger ms-1">*</span>
                                    </label>
                                    {/* <input
                    className={`form-control ${errors.visitorBirthDate && 'is-invalid'
                        }`}
                    type="text"
                    {...register('visitorBirthDate', { required: true, pattern: pattern.notAllowSpaceInStart })}
                /> */}
                                    <Controller
                                        name="dob"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange } }) => (
                                            <ReactDatePicker
                                                value={value}
                                                onChange={onChange}
                                                placeholder={t(
                                                    'guestRegistration.guestVerification.placeholder.dob'
                                                )}
                                                maxDate={moment().toDate()}
                                                dateFormat={process.env.REACT_APP_DATE_PICKER_FORMAT}
                                                hasErrors={errors.dob}
                                            />
                                        )}
                                    />
                                    {errors.dob && (
                                        <small>
                                            {errors.dob.type === 'required' && (
                                                <span className="text-danger">
                                                    {t('guestRegistration.guestVerification.error.dob')}
                                                </span>
                                            )}
                                        </small>
                                    )}
                                </div>
                                <div className="form-group col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-3">
                                    <label>
                                        {t('guestRegistration.guestVerification.label.gender')}
                                        <span className="text-danger ms-1">*</span>
                                    </label>
                                    <Controller
                                        name="gender"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange } }) => (
                                            <SearchableDropdown
                                                value={value}
                                                onChange={onChange}
                                                options={genderOptions}
                                                hasError={errors.gender}
                                                placeholder={""}
                                            />
                                        )}
                                    />
                                    {errors.gender && (
                                        <small>
                                            {errors.gender.type === 'required' && (
                                                <span className="text-danger">
                                                    {t('guestRegistration.guestVerification.error.gender')}
                                                </span>
                                            )}
                                        </small>
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-1">
                                    <label>
                                        {t('guestRegistration.guestVerification.label.destination')}
                                        <span className="text-danger ms-1">*</span>
                                    </label>
                                    <Controller
                                        name="destination_id"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange } }) => (
                                            <SearchableDropdown
                                                value={value}
                                                onChange={onChange}
                                                options={destinations}
                                                hasError={errors.destination_id}
                                                placeholder={t('guestRegistration.guestVerification.placeholder.selectdestination')}
                                            />
                                        )}
                                    />
                                    {errors.destination_id && (
                                        <small>
                                            {errors.destination_id.type === 'required' && (
                                                <span className="text-danger">
                                                    {t('guestRegistration.guestVerification.error.destination')}
                                                </span>
                                            )}
                                        </small>
                                    )}
                                </div>
                                <div className="form-group col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-1">
                                    <label>
                                        {t('guestRegistration.guestVerification.label.vehicleRegistration')}
                                    </label>
                                    <input
                                        className={`form-control ${errors.vehicle_reg_no && 'is-invalid'
                                            }`}
                                        type="text"
                                        {...register('vehicle_reg_no')}
                                    />
                                </div>
                                <div className="form-group col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-1">
                                    <label>
                                        {t('guestRegistration.guestVerification.label.accessCard')}
                                    </label>
                                    <input
                                        className={`form-control ${errors.access_card_no && 'is-invalid'
                                            }`}
                                        type="text"
                                        {...register('access_card_no')}
                                    />
                                </div>
                                <div className="form-group col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-1">
                                    <label>
                                        {t('guestRegistration.guestVerification.label.otp')}
                                        <span className="text-danger ms-1">*</span>
                                    </label>
                                    <input
                                        className={`form-control ${errors.otp && 'is-invalid'
                                            }`}

                                        type="text"
                                        {...register('otp', { required: true, pattern: pattern.otp })}
                                    />
                                    {errors.otp && (
                                        <small>
                                            {errors.otp.type === 'required' && (
                                                <span className="text-danger">
                                                    {t('guestRegistration.guestVerification.error.otp')}
                                                </span>
                                            )}
                                            {errors.otp.type === 'pattern' && (
                                                <span className="text-danger">
                                                    {t('guestRegistration.guestVerification.error.validOtp')}
                                                </span>
                                            )}
                                        </small>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                </form >
                <div className="col-md-12">
                    <div className="form-btn d-flex flex-wrap justify-content-end gap-3 pb-3 p-0">
                        <div className='p-2 col-xxl-2 col-xl-3 col-lg-3'>
                            <button
                                className="btn btn-primary addBtn"
                                onClick={() => getAccessCardNumber()}
                            >
                                {t('guestRegistration.guestVerification.label.verifyAccessCard')}
                            </button>
                        </div>
                        <div className='p-2 col-xxl-2 col-xl-3 col-lg-3'>
                            <button
                                className="btn btn-primary addBtn"
                                onClick={handleSubmit(onSubmit)}
                            >
                                {t('guestRegistration.editGuestModal.update')}
                            </button>
                        </div>
                        <div className='p-2 col-xxl-2 col-xl-3 col-lg-3'>
                            <button
                                className="btn btn-primary addBtn"
                                onClick={() => handleCloseModal()}
                            >
                                {t('guestRegistration.editGuestModal.cancel')}
                            </button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <CapturePhotoModal
                isModalOpen={isMobile === true ? false : isCapturePhotoModalOpen}
                setIsModalOpen={setIsCapturePhotoModalOpen}
                setUserPhoto={setProfilePhoto}
            />
            <CaptureDocumentModal
                isModalOpen={isCaptureFrontDocModalOpen}
                setIsModalOpen={setIsCaptureFrontDocModalOpen}
                setUserPhoto={setFrontDocImage}
            />
            <CaptureDocumentModal
                isModalOpen={isCaptureBackDocModalOpen}
                setIsModalOpen={setIsCaptureBackDocModalOpen}
                setUserPhoto={setBackDocImage}
            />
            {/* </div> */}
        </Modal >
    )
}
