import React from 'react';
import { Outlet } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import fullLogo from 'assets/logo/full-logo.png';
import Footer from 'components/footer/Footer';
import '../components/pages/home/home.css';
import { useNavigate } from 'react-router-dom';

export default function HomepageLayout() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div>
      <div className="bg-white min-vh-100">
        <div className="p-3 hompage-nav">
          <div className="container d-flex flex-wrap justify-content-between align-items-center">
            <img src={fullLogo} className="homepage-logo" />
            <Button className="btn btn-primary rounded-pill px-4 login-btn" onClick={() => navigate("/login")}>
              {t("homePage.loginBtnLabel")}
            </Button>
          </div>
        </div>
        <Outlet />
      </div>
      <div className='container'>
        <Footer textColor="#ffffff" contentPosition="center" containerClass="justify-content-left" />
      </div>
    </div>
  );
}
