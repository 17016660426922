import { guestRegistrationEndPoints } from "./config"
import httpServices from "./httpServices"

export const getDestinationList = async () => {
    return await httpServices.get(guestRegistrationEndPoints.getDestinations)
}
export const registerGuests = async (guestData) => {
    return await httpServices.post(guestRegistrationEndPoints.registerGuest, guestData)
}

export const getGuestRegistrationOTP = async (guestNoOrEmail) => {
    return await httpServices.post(guestRegistrationEndPoints.guestRegistrationOtp, guestNoOrEmail)
}

export const getListOfIdentificationType = async () => {
    return await httpServices.get(guestRegistrationEndPoints.identificationTypeList)
}
export const getMemberVerify = async (MemberData) => {
    return await httpServices.post(guestRegistrationEndPoints.getMemberDetails, MemberData)
}