import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { version } from 'config';
import { useTranslation } from 'react-i18next';

const Footer = ({ footerContentColor, textColor, containerClass = "justify-content-center" }, footerStyle = {},) => {
  const { t } = useTranslation();
  return (
    <footer className={`footer d-flex ${containerClass}`}>
      <div className="d-flex flex-wrapjustify-content-between text-center fs--1 mt-4 mb-3" style={footerStyle}>
        <Col sm="auto" md="auto">
          <p className={`mb-0  ${footerContentColor}`}  >
            {t('footer.description')}
          </p>
        </Col>
      </div>
    </footer>
  );
};

export default Footer;
