import httpServices from './httpServices';
import { frontDeskEndpoints, authEndpoints } from './config';

// visitor registration api
export const addVisitorRegistration = async visitorRegistrationData => {
  return await httpServices.post(
    frontDeskEndpoints.addVisitorRegistration,
    visitorRegistrationData
  );
};
export const sendOTP = async mobileNo => {
  return await httpServices.post(frontDeskEndpoints.sendOTP, mobileNo);
};

export const getVisitors = async () => {
  return await httpServices.get(frontDeskEndpoints.getVisitors);
};
export const logOutVisitor = async id => {
  return await httpServices.put(frontDeskEndpoints.logOutVisitor, id);
};

export const authenticateUser = async userCredentials => {
  return await httpServices.post(authEndpoints.login, userCredentials);
};

export const groupLeaderData = async groupLeaderData => {
  return await httpServices.post(
    frontDeskEndpoints.groupLeaderData,
    groupLeaderData
  );
};
export const memberLogout = async memberId => {
  return await httpServices.put(frontDeskEndpoints.memberLogout, memberId);
};
