import React, { useState, useEffect, useMemo } from 'react';
import ReactTable from 'components/common/ReactTable';
import { getVisitors, logOutVisitor } from 'services/frontDeskServices';
import toastConfig from 'components/configuration/toast-config';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { eventdata, eventnestedtabledata } from '../visitorLogout/Config'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { getEventList } from 'services/event'
import { getstatus } from 'services/event'
import { loaderContext } from 'context/Context';
import { useContext } from 'react';


export default function Events() {
    var [eventData, setEventData] = useState([]);
    const [render, setRender] = useState(false);
    const { t } = useTranslation();
    const { isLoading, setIsLoading } = useContext(loaderContext)
    const getEventData = async () => {
        setIsLoading(true)
        try {
            const { data } = await getEventList();
            setEventData(data.data)
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }
    useEffect(() => {
        getEventData();
    }, [])

    const handlestatus = async (row) => {
        console.log("row", row)
        setIsLoading(true)
        try {
            const { data } = await getstatus({
                event_id: row.original.event_id,
                event_guest_id: row.original.event_guest_id,
                status: 1
            })
            row.original.guest_arrive_datetime = data?.data[0]?.guest_arrive_datetime
            row.original.status = 1;
            setRender(!render)
            toast.success(data?.message, toastConfig)
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }



    // useEffect(async () => {
    //     try {
    //         const { data } = await getVisitors();
    //         const logInData = data.data.results.filter(data => data.timeOut == null && data.id != dataId);
    //         const addSNo = logInData.map((data, index) => ({
    //             sNo: index + 1,
    //             ...data
    //         }));
    //         setVisitorData(
    //             addSNo.map(
    //                 visitorData => (
    //                     (visitorData.timeIn = moment(
    //                         visitorData.timeIn,
    //                         process.env.REACT_APP_TIME_FORMAT_SET_12 === 'false'
    //                             ? process.env.REACT_APP_TIME_FORMAT_24
    //                             : process.env.REACT_APP_TIME_FORMAT_12
    //                     ).toDate()),
    //                     { ...visitorData }
    //                 )
    //             )
    //         );
    //         console.log('run');
    //     } catch (e) {
    //         toast.error(e.message, toastConfig);
    //     }
    // }, [dataId]);
    // console.log(visitorData);
    // const onLogout = async id => {
    //     try {
    //         const logoutData = {
    //             id: id
    //         };
    //         const { data } = await logOutVisitor(logoutData);
    //         setDataId(id);
    //         toast.success(data.data.result, toastConfig);
    //     } catch (e) {
    //         toast.error(e.message, toastConfig);
    //     }
    // };

    const columns = [
        {
            Header: t('event.id'),
            accessor: (row, index) => index + 1,
            headerClass: 'text-white',
        },
        {
            Header: t('event.dateandtime'),
            accessor: 'date',
            headerClass: 'text-white',
        },
        {
            Header: t('event.eventname'),
            accessor: 'name',
            headerClass: 'text-white',

        },
        {
            Header: t('event.member'),
            accessor: 'member_name',
            headerClass: 'text-white',
        },
        {
            Header: t('event.destination'),
            accessor: 'location',
            headerClass: 'text-white',
        },
        {
            Header: t('event.action'),
            headerClass: 'text-white',
            className: 'text-center',
            isExpand: true,
            Cell: ({ row }) => {
                return (<span
                    {...row.getToggleRowExpandedProps()}
                >
                    {row.isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </span>)
            },
        }
    ];
    const subColumns = [
        {
            Header: t('nestedtable.no'),
            accessor: (row, index) => index + 1,
            headerClass: 'text-white',
        },
        {
            Header: t('nestedtable.name'),
            accessor: 'name',
            headerClass: 'text-white',
            Cell: ({ row }) => <div style={row?.original?.is_blacklist == 1 ? { color: "var(--warning)", fontWeight: 'bold' } : {}}>{row?.original?.name}</div>
        },
        {
            Header: t('nestedtable.timein'),
            accessor: 'guest_arrive_datetime',
            headerClass: 'text-white',
            Cell: ({ row }) => {
                if (row.original.guest_arrive_datetime) {
                    var testDateUtc = moment.utc(row.original.guest_arrive_datetime);
                    var localDate = moment(testDateUtc).local();
                    return moment(localDate).format(process.env.REACT_APP_TIME_FORMAT_24)
                } else {
                    return "--"
                }
            }
        },

        {
            Header: t('nestedtable.action'),
            accessor: 'status',
            headerClass: 'text-white',
            className: 'text-end',
            Cell: ({ row }) => (
                <>
                    {row.original.status === 1 ? (
                        <p style={{ color: "#939AAC", fontWeight: "bold", margin: "0rem 0rem" }} > {t('nestedtable.arrived')}  </p>
                    ) : <button
                        onClick={() => handlestatus(row)}
                        style={{
                            backgroundColor: "#33664a",
                            color: "white",
                            border: "1px solid green",
                            borderRadius: "3px",

                        }}
                    >
                        {t('nestedtable.markarrived')}
                    </button>}
                </>
            ),
        }
    ]

    const renderRowSubComponent = React.useCallback(({ row }) => {
        let index = 1;
        const newGuestData = row.original.guest_list.map((guest) => { guest.no = index; index++; return guest })
        return (
            <div className="bg-img card nested-table-body overflow-hidden" >
                <ReactTable
                    columns={subColumns}
                    data={newGuestData ? newGuestData : []}
                    headerClass="btn-primary"
                    innerTableSearch="innertable"
                    hedingName="nestedtable.tableheading"
                    showHeader={false}
                />
            </div>
        )
    }, [render])



    return (
        <>
            <div className="container-fluid event-mainn" >
                <div className=" bg-img card table-body  overflow-hidden" >
                    <ReactTable
                        columns={columns}
                        data={eventData}
                        headerClass="btn-primary "
                        hedingName="event.tableheading"
                        isSearchBoxVisible={false}
                        ispaginationvisible={true}
                        widthClass="table-width"
                        RenderRowSubComponent={
                            renderRowSubComponent
                        }
                    />

                </div>
            </div>
        </>
    );
}
