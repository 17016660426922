import React, { useState, useEffect, useMemo } from 'react';
import ReactTable from 'components/common/ReactTable';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
// import { bookingdata } from '../visitorLogout/Config'
import { Modal, Button, Form, } from 'react-bootstrap';
import '../../../assets/css/modal.css';
import 'font-awesome/css/font-awesome.min.css';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { getGuestById, getGuestslist, registerGuest } from 'services/guestBookingService'
import countryCodes, {
    defaultCountryCode
} from 'components/configuration/country-code';
import pattern from 'components/configuration/form-pattern';
import '../visitorRegistration/css/visitor-registration.css';
import ReactDatePicker from 'components/common/ReactDatePicker';
import SearchableDropdown from 'components/common/SearchableDropdown';
import { genderOptions } from 'components/configuration/common-options';
// import { toast } from 'react-toastify';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import sweetalertConfig from 'components/configuration/sweetalert-config';
import { destinationOptions } from 'components/configuration/common-options';
import PhoneNumber from 'components/common/PhoneNumber';
import { getDestinationList, getGuestRegistrationOTP, getListOfIdentificationType } from 'services/guestRegistrationService';
import { toast } from 'react-toastify';
import toastConfig from 'components/configuration/toast-config';
import CapturePhotoModal from 'components/common/CapturePhotoModal';
import CaptureDocumentModal from 'components/common/CaptureDocumentModal';
import axios from 'axios';
import { commonScanDocEndPoints } from 'services/config';
import { loaderContext } from 'context/Context';
import { useContext } from 'react';



export default function Bookings() {
    const [bookingdata, setBookingData] = useState([]);
    const navigate = useNavigate();
    const [userPhoto, setUserPhoto] = useState("");
    const [frontDocPhoto, setFrontDocPhoto] = useState("");
    const [backDocPhoto, setBackDocPhoto] = useState("");
    const [isCaptureFrontDocModalOpen, setIsCaptureFrontDocModalOpen] = useState(false)
    const [isCaptureBackDocModalOpen, setIsCaptureBackDocModalOpen] = useState(false)
    const [isCapturePhotoModalOpen, setIsCapturePhotoModalOpen] = useState(false);
    const isMobile = navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i) ? true : false
    // const [visitorData, setVisitorData] = useState([]);
    const { isLoading, setIsLoading } = useContext(loaderContext)
    const { t } = useTranslation();
    // const [dataId, setDataId] = useState();

    // --------------------------start registerguestbooking-------------------------//

    // const [countryCode, setCountryCode] = useState(defaultCountryCode);
    // const [towersList, setTowersList] = useState([]);
    // const [companyList, setCompanyList] = useState([]);
    // const [departmentList, setDepartmentList] = useState([{ label: "One", value: "One" }, { label: "Two", value: "Two" }, { label: "Three", value: "Three" }, { label: "Four", value: "Four" }]);
    // const [showAssetModel, setShowAssetModel] = useState(false);
    // const [showLpoNumber, setShowLpoNumber] = useState(false);
    // const [showBypassModal, setShowBypassModal] = useState(false);
    // const [showEditAssetModel, setEditShowAssetModel] = useState(false);
    // const [editableAssetId, setEditableAssetId] = useState('');
    // const [listOfAssets, setListOfAssets] = useState([]);
    // const [selectCompany, setSelectCompany] = useState({});
    // const [hostList, setHostList] = useState([]);
    // const [phoneNumber, setPhoneNumber] = useState("");
    // const [email, setEmail] = useState("");
    // const [appointmentData, setAppointmentData] = useState({});
    const [visitorType, setVisitorType] = useState([{ label: "Contractor", value: "Contractor" }, { label: "Supplier", value: "Supplier" }, { label: "Casuals", value: "Casuals" }])
    const [docType, setDocType] = useState([])
    const [destinations, setDestinations] = useState([]);
    const [identitySection, setIdentitySection] = useState(true);
    // const [byPassType, setByPassType] = useState();
    const [submitPress, setSubmitPress] = useState(false)
    const params = useParams();
    const [memberData, setMemberData] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [countryCode, setCountryCode] = useState(defaultCountryCode);
    const [isVisible, setIsVisible] = useState(false)
    const [editDataId, setEditDataId] = useState();
    const [modaldata, setModalData] = useState([])
    const [frontdocerror, setFrontDocError] = useState(false)
    const [backdocerror, setBackDocError] = useState(false)
    // const assetTableHeaders = [
    //     {
    //         path: 'typeOfAsset',
    //         label: t('visitorRegistration.assetTableLabel.typeOfAsset')
    //     },
    //     {
    //         path: 'make',
    //         label: t('visitorRegistration.assetTableLabel.make')
    //     },
    //     {
    //         path: 'serialNo',
    //         label: t('visitorRegistration.assetTableLabel.serialNo')
    //     },
    //     {
    //         path: 'remark',
    //         label: t('visitorRegistration.assetTableLabel.remark')
    //     },
    //     {
    //         label: t('visitorRegistration.assetTableLabel.action'),
    //         content: dataId => (
    //             <div className="d-flex">
    //                 <button
    //                     className="btn p-0 me-2"
    //                     title="Edit asset"
    //                     type="button"
    //                     onClick={() => handleOpenEditAssetModel(dataId)}
    //                 >
    //                     {' '}
    //                     <FontAwesomeIcon icon="edit" />
    //                 </button>
    //                 <button
    //                     className="btn p-0"
    //                     type="button"
    //                     onClick={() => onDeleteAsset(dataId)}
    //                     title="Delete asset"
    //                 >
    //                     <FontAwesomeIcon icon="trash-alt" />
    //                 </button>
    //             </div>
    //         )
    //     }
    // ];


    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        control,
        setValue,
        clearErrors,
        setError,
        watch
    } = useForm();

    // const visitorContactNo = watch('visitorContactNo');
    // const companyId = watch('companyId');
    // const departmentId = watch('departmentId');
    // const selectedVisitor = watch("visitorType");
    // useEffect(() => {
    //     if (selectedVisitor?.label === "Supplier") {
    //         setShowLpoNumber(true);
    //     } else {
    //         setShowLpoNumber(false);
    //     }
    // }, [selectedVisitor])

    // useEffect(() => {
    //     if (submitPress) {
    //         if (email != "") {
    //             if (!pattern.email.test(email)) {
    //                 setError("visitorPhoneNo", { type: "pattern", message: "emailInvalid" })
    //             } else {
    //                 clearErrors("visitorPhoneNo");
    //                 setValue("visitorPhoneNo", email)
    //             }
    //         } else if (phoneNumber != "") {
    //             if (!pattern.phoneNumber.test(phoneNumber)) {
    //                 setError("visitorPhoneNo", { type: "pattern", message: "phoneNoInvalid" })
    //             } else {
    //                 clearErrors("visitorPhoneNo");
    //                 setValue("visitorPhoneNo", phoneNumber)
    //             }
    //         } else {
    //             setError("visitorPhoneNo", { type: "required" })
    //         }
    //     }
    // }, [email, phoneNumber, submitPress])

    // const resetByPassVisitor = () => {
    //     reset();
    //     setValue('departmentId', '');
    //     setValue('visitorBirthDate', '');
    //     setValue('gender', '');
    //     setValue('visitorType', '');
    //     setEmail("");
    //     setPhoneNumber("")
    // };

    // const onSendOTP = async () => {
    //     console.log('click otp btn');
    //     setSubmitPress(true)
    //     // try {
    //     //   const { data } = await sendOTP(visitorContactNo);
    //     //   console.log('data is', data);
    //     // } catch (e) {
    //     //   toast.error(e.message, toastConfig);
    //     // }
    // };
    // const onSubmitVisitorData = async visitorData => {
    //     setSubmitPress(true)
    //     try {
    //         visitorData.visitorCountryCode = countryCode.dial_code.slice(1, countryCode.dial_code.length);
    //         visitorData.gender = visitorData.gender.value;
    //         visitorData.employeeId = visitorData.employeeId.value;
    //         visitorData.companyId = visitorData.companyId.value;
    //         visitorData.departmentId = visitorData.departmentId.value;
    //         visitorData.visitorType = visitorData.visitorType.value;
    //         visitorData.visitorBirthDate = moment(visitorData.visitorBirthDate).format(
    //             'YYYY-MM-DD'
    //         );
    //         visitorData.assets = listOfAssets.map(asset => {
    //             delete asset.id;
    //             return asset;
    //         });
    //         const { data } = await addVisitorRegistration(visitorData);
    //         resetByPassVisitor();
    //         toast.success(data.data.result, toastConfig);
    //     } catch (e) {
    //         toast.error(e.message, toastConfig);
    //     }
    // };
    // const onOTPByPass = () => {
    //     setShowBypassModal(true)
    //     setByPassType("OTP")
    // }


    // --------------------------------end registerguestbooking----------------------//




    // const { control, handleSubmit, formState: { errors }, reset } = useForm();
    const destination = watch("destination_id")
    console.log("Destination", destination)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const documentType = watch("document_type");
    // useEffect(() => {
    //     if (documentType) {
    //         setFrontDocPhoto("");
    //         setBackDocPhoto("");
    //         setValue("name", "")
    //         setValue("document_id_number", "")
    //         setValue("nationality", "")
    //         setValue("dob", "")
    //         setValue("gender", "")
    //     }
    // }, [documentType])
    const handleOpenModal = async (id) => {
        setIsModalOpen(true);
        setIsLoading(true)
        try {
            // const { data } = await getGuestById({ id });
            console.log("functionstart")
            console.log("bookingdata", bookingdata)
            const editdata = bookingdata.find((edituser) => edituser.id === id)
            setModalData(editdata)
            console.log("ed", editdata)
            const { data } = await getGuestRegistrationOTP(editdata.guest_email && editdata.guest_email != "" ? { email: editdata.guest_email, meeting_date: editdata?.arrival_time, document_id_number: editdata?.doc_id_number } : {
                phone: editdata.guest_phone, country_code: editdata.guest_country_code, meeting_date: editdata?.arrival_time, document_id_number: editdata?.doc_id_number
            })
            console.log("dataaataaa", data)
            toast.success(data?.data[0]?.otp, toastConfig)
            const { data: destinationlist } = await getDestinationList();
            const { data: identificationType } = await getListOfIdentificationType();
            setDestinations(destinationlist?.data?.map((destination) => ({ label: destination.name, value: destination.id })))
            setDocType(identificationType?.data?.map((doc) => ({ documentName: doc.name, id: doc.id })))
            setEditDataId(editdata.id)

            const destination = destinationlist?.data?.find((destination) => destination?.id === editdata?.destination_id)
            if (destination) {
                editdata.destination_id = { label: destination?.name, value: destination?.id }
            }
            editdata.doc_id_type = editdata.doc_id_type.toString();
            editdata.gender = genderOptions?.find((gender) => gender?.label === editdata?.gender)

            console.log("editdatttttaaaa", editdata)
            setCountryCode((editdata?.guest_country_code && editdata?.guest_country_code != "") ? countryCodes.find(country => country.dial_code == editdata?.guest_country_code) : defaultCountryCode)
            setValue('name', editdata.guest_name);
            setValue('document_id_number', editdata.doc_id_number);
            setValue('document_type', editdata.doc_id_type);
            setValue('phone', editdata.guest_email && editdata.guest_email != "" ? editdata.guest_email : editdata.guest_phone);
            setEmail(editdata.guest_email);
            setCountryCode(countryCodes.find((country) => country.dial_code === editdata.guest_country_code))
            setPhoneNumber(editdata.guest_phone);
            setValue('nationality', editdata.nationality);
            { (editdata?.dob && editdata?.dob != "") && setValue('dob', moment(editdata?.dob).toDate()); }
            setCountryCode(countryCodes.find((country) => country.dial_code === editdata.guest_country_code))
            setValue('gender', editdata?.gender);
            { editdata?.destination_id && setValue('destination_id', editdata?.destination_id); }
            setIsVisible(true)
            setValue('otp', '');
            console.log(id)
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    };
    console.log("dataa", modaldata)

    useEffect(() => {
        if (frontDocPhoto != "") {
            setFrontDocError(false)
        }
        if (backDocPhoto != "") {
            setBackDocError(false)
        }
    }, [frontDocPhoto, backDocPhoto])

    const handleCloseModal = () => {
        setIsModalOpen(false);
        clearErrors()
        handleReset()
        setUserPhoto("");
        setFrontDocPhoto("");
        setBackDocPhoto("");
    }
    const handleReset = () => {
        reset();
        setValue("gender", "")
        setValue('phone', email)
        setUserPhoto("");
        setFrontDocPhoto("");
        setBackDocPhoto("");
        setValue("destination_id", "")
        setCountryCode(defaultCountryCode)
    }

    // useEffect(async () => {
    //     try {
    //         const { data } = await getVisitors();
    //         const logInData = data.data.results.filter(data => data.timeOut == null && data.id != dataId);
    //         const addSNo = logInData.map((data, index) => ({
    //             sNo: index + 1,
    //             ...data
    //         }));
    //         setVisitorData(
    //             addSNo.map(
    //                 visitorData => (
    //                     (visitorData.timeIn = moment(
    //                         visitorData.timeIn,
    //                         process.env.REACT_APP_TIME_FORMAT_SET_12 === 'false'
    //                             ? process.env.REACT_APP_TIME_FORMAT_24
    //                             : process.env.REACT_APP_TIME_FORMAT_12
    //                     ).toDate()),
    //                     { ...visitorData }
    //                 )
    //             )
    //         );
    //         console.log('run');
    //     } catch (e) {
    //         toast.error(e.message, toastConfig);
    //     }
    // }, [dataId]);
    // console.log(visitorData);
    // const onLogout = async id => {
    //     try {
    //         const logoutData = {
    //             id: id
    //         };
    //         const { data } = await logOutVisitor(logoutData);
    //         setDataId(id);
    //         toast.success(data.data.result, toastConfig);
    //     } catch (e) {
    //         toast.error(e.message, toastConfig);
    //     }
    // };
    const onSendOTP = async () => {
        if (!errors.phone) {
            setIsLoading(true)
            try {
                const { data } = await getGuestRegistrationOTP(modaldata.guest_email && modaldata.guest_email != "" ? { email: modaldata.guest_email, meeting_date: modaldata?.arrival_time, document_id_number: modaldata?.doc_id_number } : {
                    phone: modaldata.guest_phone, country_code: modaldata.guest_country_code, meeting_date: modaldata?.arrival_time, document_id_number: modaldata?.doc_id_number
                })
                setIsVisible(true)
                toast.success(data?.data[0]?.otp, toastConfig)
                if (!isVisible) {
                    if (data?.data[0]?.guest_details) {
                        const newData = data?.data[0]?.guest_details;
                        const { otp, ...restData } = newData;
                        console.log("Reset Data", restData)
                        restData.destination_id = destinations.find((destination) => destination.value === restData.destination_id);
                        restData.gender = genderOptions.find((gedner) => gedner.label === restData.gender)
                        setProfilePhoto(restData?.profile_photo ? restData?.profile_photo : "")
                        setFrontDocPhoto(restData?.front ? restData?.front : "")
                        setBackDocPhoto(restData?.back ? restData?.back : "")
                        // setValue("name", restData.guest_name)
                        clearErrors("name")
                        // setValue("document_id_number", restData.doc_id_number)
                        clearErrors("document_id_number")
                        // setValue("nationality", restData.nationality)
                        clearErrors("nationality")
                        // setValue("dob", moment(restData.dob).toDate())
                        clearErrors("dob")
                        // setValue("gender", restData.gender)
                        clearErrors("gender")
                        // setValue("document_type", restData?.doc_id_type?.toString())
                        clearErrors("document_type")
                        // setValue("destination_id", restData.destination_id)
                        clearErrors("destination_id")
                        // setValue("vehicle_reg_no", restData.vehicle_reg_no)
                        clearErrors("vehicle_reg_no")
                        // setValue("access_card_no", restData.access_card_no)
                        clearErrors("access_card_no")
                        // reset(restData)
                        // setSubmitPress(true)
                        console.log('click otp btn');
                    }
                }
            } catch (e) {
                if (e.response.data) {
                    if (Array.isArray(e.response.data.message)) {
                        e.response.data.message.map(errors => toast.error(errors, toastConfig))
                    } else {
                        toast.error(e.response.data.message, toastConfig)
                    }
                } else {
                    toast.error(e.message, toastConfig)
                }

            }
            setIsLoading(false)
        }
    };

    const getDataFrontDocument = async () => {
        try {
            const frontImg = frontDocPhoto
            const backImg = backDocPhoto
            const { data } = await axios.post(commonScanDocEndPoints.imageIdentity, { front: frontImg.split(',')[1], back: backImg.split(',')[1], type: documentType }, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            const documentData = data?.data[0]?.document_data;
            setValue("gender", genderOptions.find((gender) => gender.value === documentData?.gender))
            setValue("name", documentData?.name)
            setValue("document_id_number", documentData?.id_card_number)
            setValue("nationality", documentData?.nationality)
            setValue("dob", moment(documentData?.dob, process.env.REACT_APP_DISPLAY_DATE_FORMAT).toDate())
            clearErrors("gender")
            clearErrors("name")
            clearErrors("document_id_number")
            clearErrors("nationality")
            clearErrors("dob")
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
    }

    const getDataFromPassport = async () => {
        setIsLoading(true)
        try {
            const frontImg = frontDocPhoto
            const { data } = await axios.post(commonScanDocEndPoints.imageIdentity, { front: frontImg.split(',')[1], type: documentType }, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            const documentData = data?.data[0]?.document_data;
            setValue("gender", genderOptions.find((gender) => gender.value === documentData?.gender))
            setValue("name", documentData?.name)
            setValue("document_id_number", documentData?.id_card_number)
            setValue("nationality", documentData?.nationality)
            setValue("dob", moment(documentData?.dob, process.env.REACT_APP_DISPLAY_DATE_FORMAT).toDate())
            clearErrors("gender")
            clearErrors("name")
            clearErrors("document_id_number")
            clearErrors("nationality")
            clearErrors("dob")
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (documentType != "4") {
            if (documentType != "3") {
                if (frontDocPhoto && frontDocPhoto != "" && backDocPhoto && backDocPhoto != "") {
                    getDataFrontDocument()
                }
            }
            else {
                if (frontDocPhoto && frontDocPhoto != "") {
                    getDataFromPassport()
                }
            }
        }

    }, [frontDocPhoto, backDocPhoto])

    const onSubmit = async (formData) => {
        console.log("formdat===============================", formData)
        setIsLoading(true)
        let isError = false
        if (documentType == 3 && frontDocPhoto == "") {
            setFrontDocError(true)
            isError = true
        }
        else if (documentType != 3) {
            if (frontDocPhoto == "") {
                setFrontDocError(true);
                isError = true
            }
            if (backDocPhoto == "") {
                setBackDocError(true);
                isError = true
            }
        }

        if (!isError) {
            try {
                formData.gender = formData.gender.label
                formData.destination_id = formData.destination_id.value
                formData.dob = moment(formData?.dob).format(process.env.REACT_APP_DATE_FORMAT)
                formData.email = email;
                formData.id = editDataId;
                formData.phone = phoneNumber;
                formData.profile_photo = userPhoto;
                formData.front = frontDocPhoto;
                formData.back = backDocPhoto;
                const { data } = await registerGuest(formData)
                if (data?.data[0]?.visited_count == 1) {
                    toast.warning(data?.data[0]?.visited_message, toastConfig)
                } else {
                    toast.success(data?.message, toastConfig)
                }
                setBookingData(bookingdata.filter((booking) => booking?.id != formData?.id))
            }
            catch (e) {
                if (e.response.data) {
                    if (Array.isArray(e.response.data.message)) {
                        e.response.data.message.map(errors => toast.error(errors, toastConfig))
                    } else {
                        toast.error(e.response.data.message, toastConfig)
                    }
                } else {
                    toast.error(e.message, toastConfig)
                }
            }
            setIsModalOpen(false);
            reset();
            setProfilePhoto("")
            setFrontDocPhoto("")
            setBackDocPhoto("")
            console.log("clicked");
        }
        setIsLoading(false)

    }

    useEffect(async () => {
        setIsLoading(true)
        try {
            const { data } = await getGuestslist();
            setBookingData(data.data)
            setCountryCode(countryCodes.find((country) => country.dial_code === data?.data?.guest_country_code))
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }, [])


    const columns = [
        {
            Header: t('bookings.no'),
            accessor: (row, index) => index + 1,
            headerClass: 'text-white',
        },
        {
            Header: t('bookings.guestname'),
            accessor: 'guest_name',
            headerClass: 'text-white',
            Cell: ({ row }) => {
                return (
                    <div>
                        <img
                            className="me-2 rounded-pill"
                            src={row.original.visitorImg}
                            height={35}
                            width={35}
                        ></img>
                        <span className="class-for-name">{row.original.guest_name}</span>
                    </div>
                );
            }
        },
        {
            Header: t('bookings.invitedby'),
            accessor: 'member_details.name',
            headerClass: 'text-white',
            Cell: ({ row }) => {
                return (
                    <div>
                        {row?.origional?.member_details?.name ? row?.origional?.member_details?.name : "--"}
                    </div>
                );
            }
        },
        {
            Header: t('bookings.bookingtime'),
            accessor: 'member_details.created_at',
            headerClass: 'text-white',
            Cell: ({ row }) => (
                moment.utc(row?.original?.member_details?.created_at).local().format(process.env.REACT_APP_TIME_FORMAT_24)
            )
        },
        {
            Header: t('bookings.action'),
            headerClass: 'text-white',
            className: 'text-center',
            Cell: ({ row }) => (
                <button
                    className="btn-register  border-0 btn-sm"
                    // onClick={() => handleregister(row.original)}
                    onClick={() => handleOpenModal(row.original.id)}
                >
                    {t('bookings.btnregister')}
                </button>
            )
        }
    ];
    const setProfilePhoto = (data) => {
        setUserPhoto(data);
    }
    const setFrontDocImage = (data) => {
        setFrontDocPhoto(data);
    }
    const setBackDocImage = (data) => {
        setBackDocPhoto(data);
    }
    const handleCapture = (target) => {
        if (target.files) {
            if (target.files.length !== 0) {
                const file = target.files[0];
                const newUrl = URL.createObjectURL(file);
                setUserPhoto(newUrl);
            }
        }
    };
    const frontsideClick = () => {
        if (documentType && documentType != '') {
            setIsCaptureFrontDocModalOpen(true)
        } else {
            toast.error("Please select ID Type first.", toastConfig)
        }
    }
    const backsideClick = () => {
        if (documentType && documentType != '') {
            setIsCaptureBackDocModalOpen(true)
        } else {
            toast.error("Please select ID Type first.", toastConfig)
        }
    }

    return (
        <>
            <div className="container-fluid event-mainn" >
                <div className=" bg-img card table-body overflow-hidden">
                    <ReactTable
                        columns={columns}
                        data={bookingdata}
                        headerClass="btn-primary"
                        hedingName="bookings.tableheading"
                        isSearchBoxVisible={false}
                        ispaginationvisible={true}
                        widthClass="table-width"
                    />
                </div>
            </div>


            {
                isModalOpen && (
                    <Modal show={isModalOpen} onHide={handleCloseModal} >
                        <Modal.Header className="heading-color fw-600 guestregistermodalheader" >{t('bookings.registerguestbooking.registerguestbookingheading')}
                            <i closeButton className="fa  fa-times-circle modal-close-btn" aria-hidden="true" onClick={handleCloseModal}></i>
                        </Modal.Header>
                        <Modal.Body className='modalbody'>
                            <Form onSubmit={handleSubmit(onSubmit)}>

                                <div className="row g-3 mb-3">
                                    <div className='table-body'>
                                        <div className="overfolw-hidden mb-3">
                                            <div className="card-header pb-0">
                                                {/* <div className="row">
                                                    <h3 className="heading-color fw-600">
                                                        {t('bookings.registerguestbooking.registerguestbookingheading')}
                                                    </h3>

                                                    <div className="col-12">
                                                        <hr />
                                                    </div>
                                                </div> */}
                                            </div>

                                            <div className="card-body pt-0" style={{ marginTop: "-2rem" }}>
                                                <div className="card-body pt-3">
                                                    <div>
                                                        <div className="row m-0">
                                                            <div className="form-group p-0 col-12">
                                                                <label>
                                                                    {t('guestRegistration.guestVerification.label.waNumber')}
                                                                    <span className="text-danger ms-1">*</span>
                                                                </label>
                                                                <div className="d-flex flex-wrap p-0 col-12 gap-3">

                                                                    <Controller
                                                                        name="phone"
                                                                        control={control}
                                                                        rules={{ required: true }}
                                                                        render={({ field: { onChange, value } }) => (
                                                                            <PhoneNumber
                                                                                value={value}
                                                                                onChange={onChange}
                                                                                phoneNumber={phoneNumber}
                                                                                email={email}
                                                                                setPhoneNumber={setPhoneNumber}
                                                                                firstError={errors?.guest_phone?.type === 'required' || errors?.guest_phone?.message == "phoneNoInvalid"}
                                                                                secondError={errors?.guest_email?.type === 'required' || errors?.guest_email?.message == "emailInvalid"}
                                                                                setEmail={setEmail}
                                                                                countryCode={countryCode}
                                                                                firstPlaceholder={t("guestRegistration.guestVerification.placeholder.waNumber")}
                                                                                secondPlaceholder={t("guestRegistration.guestVerification.placeholder.email")}
                                                                                setCountryCode={setCountryCode}
                                                                                dropdownDirection="bottom"
                                                                                saperate={true}
                                                                                disableboth={true}
                                                                                wantemail={true}
                                                                                flagvisible={true}
                                                                            />
                                                                        )}
                                                                    />
                                                                    {
                                                                        isVisible ? (
                                                                            <button
                                                                                className="btn btn-primary sendotp"
                                                                                type="button"
                                                                                onClick={() => { setSubmitPress(true); onSendOTP() }}
                                                                            >
                                                                                {t('guestRegistration.guestVerification.label.resentOtp')}
                                                                            </button>
                                                                        ) : (
                                                                            <button
                                                                                className="btn btn-primary sendotp"
                                                                                type="button"
                                                                                onClick={() => { setSubmitPress(true); onSendOTP() }}
                                                                            >
                                                                                {t('guestRegistration.guestVerification.label.sentOtp')}
                                                                            </button>
                                                                        )
                                                                    }
                                                                    {/* <button
                                                                        className="btn btn-primary sendotp"
                                                                        type="button"
                                                                        onClick={() => { setSubmitPress(true); onSendOTP() }}
                                                                    >
                                                                        {t('guestRegistration.guestVerification.label.sentOtp')}
                                                                    </button> */}
                                                                </div>
                                                                {errors.phone && (
                                                                    <>
                                                                        <small>
                                                                            {errors.phone.type === 'required' && (
                                                                                <span className="text-danger">
                                                                                    {t(
                                                                                        'guestRegistration.guestVerification.error.guestContactNoReq'
                                                                                    )}
                                                                                </span>
                                                                            )}
                                                                        </small>
                                                                        <small>
                                                                            {errors.phone.type === 'pattern' && (
                                                                                <span className="text-danger">
                                                                                    {t(`guestRegistration.guestVerification.error.${errors.guest_phone.message}`)}
                                                                                </span>
                                                                            )}
                                                                        </small>
                                                                    </>
                                                                )}
                                                                <div>
                                                                    <label className='pt-2' style={{ color: 'var("--primary")' }}>
                                                                        {t('guestRegistration.guestVerification.error.emailReq')}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row align-items-center">
                                                    {identitySection && <div
                                                        className="col-lg-12 rounded-3"
                                                    >
                                                        <div className="documentid-data col-12">
                                                            <div className="row align-items-center">
                                                                {<div className="col-md-12">
                                                                    <label className="form-label">
                                                                        {t('bookings.label.idtype')}
                                                                        <span className="text-danger ms-1">*</span>
                                                                    </label>
                                                                    <div className='form-group mb-3 mb-sm-0'>
                                                                        {docType.map((docType, index) => (
                                                                            <div className="form-check form-check-inline" key={docType.id}>
                                                                                <input
                                                                                    className="form-check-input"
                                                                                    // checked={index === 0 && true}
                                                                                    type="radio"
                                                                                    value={docType.id}
                                                                                    {...register('document_type', {
                                                                                        required: true
                                                                                    })}
                                                                                />
                                                                                <label className="form-check-label">
                                                                                    {docType.documentName}
                                                                                </label>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                    {errors.document_type && (
                                                                        <small>
                                                                            {errors.document_type.type === 'required' && (
                                                                                <span className="text-danger">
                                                                                    {t(
                                                                                        'bookings.error.doctypeerror'
                                                                                    )}
                                                                                </span>
                                                                            )}
                                                                        </small>
                                                                    )}
                                                                </div>}
                                                            </div>
                                                        </div>
                                                    </div>}
                                                    {isMobile && <input
                                                        accept="image/*"
                                                        style={{ display: "none" }}
                                                        id="icon-button-file"
                                                        type="file"
                                                        capture="environment"
                                                        onChange={(e) => handleCapture(e.target)}
                                                    />}
                                                    <div className="col-lg-12 mt-3 mt-sm-0">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-6">
                                                                <label htmlFor='icon-button-file' style={{ width: "100%" }}>
                                                                    <div className="scan-box-modal d-felx align-items-center justify-content-center" onClick={() => setIsCapturePhotoModalOpen(true)}>
                                                                        <img
                                                                            src={`${userPhoto != "" ? userPhoto : "/admin-images/user.png"}`}
                                                                            className="img-fluid"
                                                                            style={userPhoto === "" ? { maxWidth: '10rem' } : { height: "96%", width: "96%" }}
                                                                        />
                                                                        <h4 className='scan-box-modal-text'>{t('bookings.label.capturePhoto')}</h4>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6 mt-3 mt-sm-0">
                                                                <div>
                                                                    <div className="scan-box-modal" onClick={() => frontsideClick()}>
                                                                        <img
                                                                            src={`${frontDocPhoto != "" ? frontDocPhoto : "/admin-images/scanner.png"}`}
                                                                            className="img-fluid"
                                                                            style={frontDocPhoto === "" ? { maxWidth: '10rem' } : { height: "96%", width: "96%" }}
                                                                        />
                                                                        <h4 className='scan-box-modal-text'>{documentType != "3" ? t('bookings.label.scanfrontDoc') : t('bookings.label.scanDoc')}</h4>
                                                                    </div>
                                                                    {frontdocerror && (
                                                                        <small className='frontdocerror'>
                                                                            <span className="text-danger">
                                                                                Front Doc is required.
                                                                            </span>
                                                                        </small>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            {documentType != "3" && <div className="col-lg-4 col-md-6 mt-3 mt-sm-0">
                                                                <div>
                                                                    <div className="scan-box-modal" onClick={() => backsideClick()}>
                                                                        <img
                                                                            src={`${backDocPhoto != "" ? backDocPhoto : "/admin-images/scanner.png"}`}
                                                                            className="img-fluid"
                                                                            style={backDocPhoto === "" ? { maxWidth: '10rem' } : { height: "96%", width: "96%" }}
                                                                        />
                                                                        <h4 className='scan-box-modal-text'>{t('bookings.label.scanbackDoc')}</h4>
                                                                    </div>
                                                                    {backdocerror && (
                                                                        <small className='frontdocerror'>
                                                                            <span className="text-danger">
                                                                                Back Doc is required.
                                                                            </span>
                                                                        </small>
                                                                    )}
                                                                </div>
                                                            </div>}
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-12">
                                                    <hr />
                                                </div>
                                                <div className="col-md-12 mb-3">
                                                    <div className='container-fluid'>
                                                        <div className="row">
                                                            <div className="col-md-6 mb-3">
                                                                <label className="form-label">
                                                                    {t('bookings.label.docidnumber')}
                                                                    <span className="text-danger ms-1">*</span>
                                                                </label>
                                                                <input
                                                                    className={`form-control ${errors.document_id_number && 'is-invalid'
                                                                        }`}
                                                                    type="text"
                                                                    {...register('document_id_number', {
                                                                        required: true,
                                                                        pattern: pattern.notAllowSpaceInStart
                                                                    })}
                                                                />
                                                                {errors.document_id_number && (
                                                                    <small>
                                                                        {errors.document_id_number.type === 'required' && (
                                                                            <span className="text-danger">
                                                                                {t(
                                                                                    'bookings.error.documentIdNumber'
                                                                                )}
                                                                            </span>
                                                                        )}
                                                                        {errors.document_id_number.type === 'pattern' && (
                                                                            <span className="text-danger">
                                                                                {t(
                                                                                    'bookings.error.documentIdNumberInvalid'
                                                                                )}
                                                                            </span>
                                                                        )}
                                                                    </small>
                                                                )}
                                                            </div>




                                                            <div className="form-group col-md-6 mb-3">
                                                                <label>
                                                                    {t('bookings.label.nationality')}
                                                                    <span className="text-danger ms-1">*</span>
                                                                </label>
                                                                <input
                                                                    className={`form-control ${errors.nationality && 'is-invalid'
                                                                        }`}

                                                                    type="text"
                                                                    // disabled
                                                                    {...register('nationality', {
                                                                        required: true,
                                                                        pattern: pattern.nationality
                                                                    })}
                                                                />
                                                                {errors.nationality && (
                                                                    <small>
                                                                        {errors.nationality.type === 'required' && (
                                                                            <span className="text-danger">
                                                                                {t('bookings.error.nationality')}
                                                                            </span>
                                                                        )}
                                                                        {errors.nationality.type === 'pattern' && (
                                                                            <span className="text-danger">
                                                                                {t('bookings.error.nationalityInvalid')}
                                                                            </span>
                                                                        )}
                                                                    </small>
                                                                )}
                                                            </div>
                                                            <div className="form-group col-md-6 mb-3">
                                                                <label>
                                                                    {t('bookings.label.fullname')}
                                                                    <span className="text-danger ms-1">*</span>
                                                                </label>
                                                                <input
                                                                    className={`form-control ${errors.name && 'is-invalid'
                                                                        }`}
                                                                    type="text"
                                                                    {...register('name', {
                                                                        required: true,
                                                                        pattern: pattern.fullName
                                                                    })}
                                                                />
                                                                {errors.name && (
                                                                    <small>
                                                                        {errors.name.type === 'required' && (
                                                                            <span className="text-danger">
                                                                                {t('bookings.error.fullNameReq')}
                                                                            </span>
                                                                        )}
                                                                        {errors.name.type === 'pattern' && (
                                                                            <span className="text-danger">
                                                                                {t('bookings.error.fullNameInvalid')}
                                                                            </span>
                                                                        )}
                                                                    </small>
                                                                )}
                                                            </div>
                                                            <div className="form-group col-md-6 mb-3">
                                                                <label>
                                                                    {t('bookings.label.dob')}
                                                                    <span className="text-danger ms-1">*</span>
                                                                </label>
                                                                {/* <input

                                                                    className={`form-control ${errors.dob && 'is-invalid'
                                                                        }`}
                                                                    type="text"
                                                                    {...register('dob', { required: true, pattern: pattern.notAllowSpaceInStart })}
                                                                /> */}
                                                                <Controller
                                                                    name="dob"
                                                                    control={control}
                                                                    rules={{ required: true }}
                                                                    render={({ field: { value, onChange } }) => (
                                                                        <ReactDatePicker
                                                                            value={value}
                                                                            onChange={onChange}
                                                                            placeholder={t(
                                                                                'visitorRegistration.placeholder.dob'
                                                                            )}
                                                                            hasErrors={errors.dob}
                                                                            maxDate={moment().toDate()}
                                                                            dateFormat={process.env.REACT_APP_DATE_PICKER_FORMAT}
                                                                        />
                                                                    )}
                                                                />
                                                                {errors.dob && (
                                                                    <small>
                                                                        {errors.dob.type === 'required' && (
                                                                            <span className="text-danger">
                                                                                {t('bookings.error.birthDateReq')}
                                                                            </span>
                                                                        )}
                                                                    </small>
                                                                )}
                                                            </div>
                                                            <div className="form-group col-md-6 mb-3">
                                                                <label>
                                                                    {t('bookings.label.gender')}
                                                                    <span className="text-danger ms-1">*</span>
                                                                </label>
                                                                <Controller
                                                                    name="gender"
                                                                    control={control}
                                                                    rules={{ required: true }}
                                                                    render={({ field: { value, onChange } }) => (
                                                                        <SearchableDropdown
                                                                            value={value}
                                                                            onChange={onChange}
                                                                            options={genderOptions}
                                                                            placeholder={t(
                                                                                'visitorRegistration.placeholder.gender'
                                                                            )}
                                                                            hasError={errors.gender}
                                                                        />
                                                                    )}
                                                                />
                                                                {errors.gender && (
                                                                    <small>
                                                                        {errors.gender.type === 'required' && (
                                                                            <span className="text-danger">
                                                                                {t('bookings.error.genderReq')}
                                                                            </span>
                                                                        )}
                                                                    </small>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <hr />
                                                </div>

                                                <div className="col-md-12">
                                                    <div className='container-fluid'>
                                                        <div
                                                            className="row pt-3 for-office"
                                                            style={{ backgroundColor: '#f5f5f5' }}
                                                        >
                                                            <h5 className="mb-3 fw-600">
                                                                {t('visitorRegistration.forOfficePurpose')}
                                                            </h5>

                                                            <div className="form-group col-md-6 col-sm-6 mb-3">
                                                                <label>
                                                                    {t('bookings.label.destination')}
                                                                    <span className="text-danger ms-1">*</span>
                                                                </label>
                                                                <Controller
                                                                    name="destination_id"
                                                                    control={control}
                                                                    rules={{ required: true }}
                                                                    render={({ field: { value, onChange } }) => (
                                                                        <SearchableDropdown
                                                                            value={value}
                                                                            onChange={onChange}
                                                                            options={destinations}
                                                                            hasError={errors.destination_id}
                                                                            placeholder={t('bookings.placeholder.selectdestination')}
                                                                        />
                                                                    )}
                                                                />
                                                                {errors.destination_id && (
                                                                    <small>
                                                                        {
                                                                            errors.destination_id.type == 'required' && (
                                                                                <span className="text-danger">
                                                                                    {t('bookings.error.destination')}
                                                                                </span>
                                                                            )
                                                                        }
                                                                    </small>
                                                                )}
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <div className='col-md-12'>
                                                    <div className='container-fluid'>
                                                        <div className='row'>
                                                            <div className="form-group col-md-6 col-sm-6 mb-1">
                                                                <label>
                                                                    {t('bookings.label.destination')}
                                                                    <span className="text-danger ms-1">*</span>
                                                                </label>
                                                                <Controller
                                                                    name="destination_id"
                                                                    control={control}
                                                                    rules={{ required: true }}
                                                                    render={({ field: { value, onChange } }) => (
                                                                        <SearchableDropdown
                                                                            value={value}
                                                                            onChange={onChange}
                                                                            options={destinations}
                                                                            hasError={errors.destination_id}
                                                                            placeholder={t('bookings.placeholder.selectdestination')}
                                                                        />
                                                                    )}
                                                                />
                                                                {errors.destination_id && (
                                                                    <small>
                                                                        {
                                                                            errors.destination_id.type == 'required' && (
                                                                                <span className="text-danger">
                                                                                    {t('bookings.error.destination')}
                                                                                </span>
                                                                            )
                                                                        }
                                                                    </small>
                                                                )}
                                                            </div>





                                                            <div className="form-group col-md-6 col-sm-6 mb-3">
                                                                <label>
                                                                    {t('bookings.label.otp')}
                                                                    <span className="text-danger ms-1">*</span>
                                                                </label>
                                                                <div className="d-flex gap-2">
                                                                    <input
                                                                        type="text"
                                                                        style={{ maxWidth: "15rem" }}
                                                                        className={`form-control ${errors.otp && 'is-invalid'
                                                                            }`}
                                                                        placeholder={t('visitorRegistration.placeholder.vrn')}
                                                                        {...register('otp', {
                                                                            required: true,
                                                                            pattern: pattern.otp
                                                                        })}
                                                                    />
                                                                </div>
                                                                {errors.otp && (
                                                                    <small>
                                                                        {errors.otp.type == 'required' && (
                                                                            <span className="text-danger">
                                                                                {t('bookings.error.otprequired')}
                                                                            </span>
                                                                        )}
                                                                        {errors.otp.type == 'pattern' && (
                                                                            <span className="text-danger">
                                                                                {t('bookings.error.otppattern')}
                                                                            </span>
                                                                        )}
                                                                    </small>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}

                                                <div className="col-md-12">
                                                    <div className="row justify-content-between">
                                                        <div className="form-group col-12 mb-3">
                                                            <label>
                                                                {t('bookings.label.otp')}
                                                                <span className="text-danger ms-1">*</span>
                                                            </label>
                                                            <div className="d-flex flex-wrap gap-2">
                                                                <input
                                                                    type="text"
                                                                    style={{ maxWidth: "15rem" }}
                                                                    className={`form-control ${errors.otp && 'is-invalid'
                                                                        }`}
                                                                    placeholder={t('visitorRegistration.placeholder.vrn')}
                                                                    {...register('otp', {
                                                                        required: true,
                                                                        pattern: pattern.otp
                                                                    })}
                                                                />
                                                                <div className="d-flex gap-2">
                                                                    <button
                                                                        className="btn btn-primary btn-lg"
                                                                        type="submit"
                                                                        onClick={() => setSubmitPress(true)}
                                                                    >
                                                                        {t('bookings.label.submit')}
                                                                    </button>
                                                                    <button
                                                                        className="btn btn-danger btn-lg"
                                                                        type="reset"
                                                                        onClick={handleReset}
                                                                    >
                                                                        {t('bookings.label.reset')}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            {errors.otp && (
                                                                <small>
                                                                    {errors.otp.type == 'required' && (
                                                                        <span className="text-danger">
                                                                            {t('bookings.error.otprequired')}
                                                                        </span>
                                                                    )}
                                                                    {errors.otp.type == 'pattern' && (
                                                                        <span className="text-danger">
                                                                            {t('bookings.error.otppattern')}
                                                                        </span>
                                                                    )}
                                                                </small>
                                                            )}

                                                        </div>

                                                        {/* <div className="col-md-6 col-12 mb-3" style={{ display: "flex", alignSelf: "flex-end", justifyContent: "flex-end" }}>
                                                            <div className="d-flex gap-2">
                                                                <button
                                                                    className="btn btn-primary btn-lg"
                                                                    type="submit"
                                                                    onClick={() => setSubmitPress(true)}
                                                                >
                                                                    {t('bookings.label.submit')}
                                                                </button>
                                                                <button
                                                                    className="btn btn-danger btn-lg"
                                                                    type="reset"
                                                                    onClick={handleReset}
                                                                >
                                                                    {t('bookings.label.reset')}
                                                                </button>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>

                                                {/* <div className="col-md-12">
                                                    <div className="form-group col-md-6 col-sm-6 mb-3">
                                                        <label>
                                                            {t('bookings.label.otp')}
                                                            <span className="text-danger ms-1">*</span>
                                                        </label>
                                                        <div className="d-flex gap-2">
                                                            <input
                                                                type="text"
                                                                style={{ maxWidth: "15rem" }}
                                                                className={`form-control ${errors.otp && 'is-invalid'
                                                                    }`}
                                                                placeholder={t('visitorRegistration.placeholder.vrn')}
                                                                {...register('otp', {
                                                                    required: true,
                                                                    pattern: pattern.otp
                                                                })}
                                                            />
                                                        </div>
                                                        {errors.otp && (
                                                            <small>
                                                                {errors.otp.type == 'required' && (
                                                                    <span className="text-danger">
                                                                        {t('bookings.error.otprequired')}
                                                                    </span>
                                                                )}
                                                                {errors.otp.type == 'pattern' && (
                                                                    <span className="text-danger">
                                                                        {t('bookings.error.otppattern')}
                                                                    </span>
                                                                )}
                                                            </small>
                                                        )}
                                                    </div>
                                                    <div className="row justify-content-between">
                                                        <div className="col-12 d-flex justify-content-end form-group mb-3">
                                                            <div className="d-flex gap-2">
                                                                <button
                                                                    className="btn btn-primary btn-lg"
                                                                    type="submit"
                                                                    onClick={() => setSubmitPress(true)}
                                                                >
                                                                    {t('bookings.label.submit')}
                                                                </button>
                                                                <button
                                                                    className="btn btn-danger btn-lg"
                                                                    type="reset"
                                                                    onClick={handleReset}
                                                                >
                                                                    {t('bookings.label.reset')}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </Modal.Body>
                        <CapturePhotoModal
                            isModalOpen={isMobile === true ? false : isCapturePhotoModalOpen}
                            setIsModalOpen={setIsCapturePhotoModalOpen}
                            setUserPhoto={setProfilePhoto}
                        />
                        <CaptureDocumentModal
                            isModalOpen={isCaptureFrontDocModalOpen}
                            setIsModalOpen={setIsCaptureFrontDocModalOpen}
                            setUserPhoto={setFrontDocImage}
                        />
                        <CaptureDocumentModal
                            isModalOpen={isCaptureBackDocModalOpen}
                            setIsModalOpen={setIsCaptureBackDocModalOpen}
                            setUserPhoto={setBackDocImage}
                        />
                        {/* </div> */}
                    </Modal>
                )
            }
        </>
    );
}
