import React from 'react';
import { useTranslation } from 'react-i18next';
import VerifyOtpForm from '../VerifyOtpForm';
import Logo from "../../../assets/logo/Centenary_Logo.png"
import "../css/forgot-password.css"

export default function VerifyOtp() {
  const { t } = useTranslation();
  return (
    <div>
      <div className="mb-2 d-flex flex-column align-items-center">
        <img src={Logo} className='company-logo' />
        <h2 className="mt-4">{t("verifyOtpPage.heading")}</h2>
        <p className='text-black'>{t("verifyOtpPage.description")}</p>
      </div>
      <VerifyOtpForm />
    </div>
  );
}
